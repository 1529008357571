import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Item = styled(motion.div)`
  flex: 1;
  border-radius: 24.03px;
  background-color: #f8f8f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-basis: calc(
    50% - 1.2rem
  ); // Adjust the 1.2rem based on the gap size between items
  flex-grow: 1; // Ensures items grow equally to fill available space
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4.206rem 2.437rem;
  box-sizing: border-box;
  min-width: 24.438rem;
  max-width: 100%;
  height: 15rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 825px) {
    min-width: 100%;
  }
`;

const ItemContent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.6rem;
  overflow: hidden;
`;

const ItemTitle = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 2.813rem;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;

  @media (max-width: 825px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  @media (max-width: 450px) {
    font-size: 1.375rem;
    line-height: 1.688rem;
  }
`;

const ItemDescription = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.35rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 450px) {
    font-size: 1.063rem;
    line-height: 1.938rem;
  }
`;

const ItemCard = ({
  title,
  description,
  initial,
  whileInView,
  viewport,
  transition,
}) => {
  return (
    <Item
      initial={initial}
      whileInView={whileInView}
      viewport={viewport}
      transition={transition}
    >
      <ItemContent>
        <ItemTitle>{title}</ItemTitle>
        <ItemDescription>{description}</ItemDescription>
      </ItemContent>
    </Item>
  );
};

export default ItemCard;
