import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const CardContainer = styled(motion.div)`
  width: 41.713rem;
  border-radius: 22.86px;
  background-color: #f8f8f8;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  padding: ${(props) =>
    props.propPadding || "4.568rem 1.25rem 0.031rem 1.437rem"};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  gap: 2.831rem;
  min-width: 41.713rem;
  max-width: 100%;
  text-align: center;
  font-size: 2.856rem;
  color: var(--text-default);
  font-family: var(--heading-h6);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 825px) {
    gap: 1.438rem;
    padding-top: 3rem;
    padding-bottom: 1.25rem;
    min-width: 100%;
  }

  @media (max-width: 1575px) {
    flex: 1;
  }
`;

const ContentWrapper = styled.div`
  width: 28.569rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.575rem;
  max-width: 100%;
`;

const Title = styled.div`
  align-self: stretch;
  position: relative;
  color: #000000;

  letter-spacing: -0.03em;
  line-height: 3.438rem;
  font-weight: 500;

  @media (max-width: 825px) {
    font-size: 2.313rem;
    line-height: 2.75rem;
  }

  @media (max-width: 450px) {
    font-size: 1.688rem;
    line-height: 2.063rem;
  }
`;

const Description = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.288rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  color: #000000;

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 1.875rem;
  }
`;

const ImageWrapper = styled.div`
  width: 28.65rem;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0.812rem 0rem 0.625rem;
  box-sizing: border-box;
  max-width: 100%;
`;

const ImageContainer = styled.div`
  width: 28.575rem;
  border-top-left-radius: 57.14px;
  border-top-right-radius: 57.14px;
  border-bottom: none;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.425rem 1.687rem 0.006rem;
  max-width: 105%;
  border-top: 11.4px solid black;
  border-right: 11.4px solid black;
  border-left: 11.4px solid black;
`;

const AppImage = styled.img`
  width: 23.713rem;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-width: 100%;
`;

const Card = ({
  className = "",
  forConsumers,
  enjoyFlexiblePaymentOptio,
  suitYourBudget,
  app,
  propPadding,
}) => {
  return (
    <CardContainer
      className={className}
      propPadding={propPadding}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <ContentWrapper>
        <Title>{forConsumers}</Title>
        <Description>
          {enjoyFlexiblePaymentOptio}
          {suitYourBudget}
        </Description>
      </ContentWrapper>
      <ImageWrapper>
        <ImageContainer>
          <AppImage loading="lazy" alt="" src={app} />
        </ImageContainer>
      </ImageWrapper>
    </CardContainer>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  forConsumers: PropTypes.string,
  enjoyFlexiblePaymentOptio: PropTypes.string,
  suitYourBudget: PropTypes.string,
  app: PropTypes.string,
  propPadding: PropTypes.string,
};

export default Card;
