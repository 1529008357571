import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./DistFinalSet.scss";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GES, GESRecords } from "../../Utilsx/Encrypt";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { maskAadhaar } from "../utils/validations";
import { faL } from "@fortawesome/free-solid-svg-icons";
import TextInputWrapper from "../components/TextInputWrapper";
import useStore from "../../store";

const DistFinalSet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let domain = `https://api.greattr.com`;
  // let domain = `http://localhost:3000`;
  localStorage.clear();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);

  const [state, setState] = useState(() => {
    if (user && Object.keys(user).length > 0) {
      return user;
    }

    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  const [fwdp, setfwdp] = useState(null);
  const [transactionId, settransactionId] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill("")); // Initialize OTP state
  const [otpError, setOtpError] = useState(null); // State for error messages
  const [isLoading, setisLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State for captcha
  const [isOtpSent, setisOtpSent] = useState(false);

  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  let p1data = GESRecords(state, apiEN);
  const [okycdata, setokycdata] = useState(null);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false; // Only allow numeric input
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus on the next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      e.preventDefault();
      // Move focus to the previous input field if it exists
      if (index > 0) {
        const previousInput = document.querySelectorAll(".otpstyle")[index - 1];
        previousInput.focus();
        handleChange({ value: "" }, index - 1);
      }
    }
  };

  const generateCaptcha = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_LINK}/kyc/generate-captcha`,
        method: "POST",
        responseType: "json",
        data: {
          uid: state?.AadhaarNumber.replace(/-/g, ""),
          uniqueId: state?.MobileNumber,
        },
      });
      if (response.status === 500) {
        alert("Some problem has occured . Please try again later");
      } else {
        console.log("/kyc/generate-captcha", response?.data);
        setCaptchaValue("");
        setokycdata(response?.data);
      }
    } catch (err) {
      if (err.response) {
        // Server responded with a status other than 2xx
        alert(err.response.data.error);
      } else if (err.request) {
        // Request was made but no response was received
        alert("No response received. Please check your network connection.");
      } else {
        // Something else happened
        alert("An error occurred. Please try again.");
      }
    }
  };

  const ResendAadharOtp = async () => {
    setisOtpSent(false);
    generateCaptcha();
    setOtp(new Array(6).fill(""));
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const getOtp = async () => {
    try {
      if (!captchaValue) {
        alert("Please complete the captcha.");
        return;
      }

      setisLoading(true);

      // Verify the captcha
      const captchaResponse = await axios.post(
        `${process.env.REACT_APP_API_LINK}/kyc/generate-otp`,
        {
          captcha: captchaValue,
          uid: state.AadhaarNumber,

          ...okycdata,
        }
      );

      console.log("get otp response", captchaResponse);

      console.log("response data", captchaResponse.data);

      if (captchaResponse.data.statusCode === 200) {
        setokycdata(captchaResponse.data);
      }
      console.log("OKYC data", okycdata);

      if (
        captchaResponse.data.statusCode === 200 &&
        captchaResponse.data.status === true &&
        captchaResponse.data.message ===
          "Successfully generated One Time Password (OTP)."
      ) {
        setisOtpSent(true);
        alert("OTP Sent Successfully");
      } else if (captchaResponse.data.statusCode !== 200) {
        alert("Captcha verification failed. Please try again.");
      } else {
        alert(captchaResponse.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setisLoading(false);
    }
  };

  const submitOkycOtp = async () => {
    try {
      console.log("lassii");
      console.log({
        ...okycdata,
        maskedAadharNumber: maskAadhaar(state?.AadhaarNumber.replace(/-/g, "")),
        otp: otp.join(""),
        p1data: {
          ...state,
          maskedAadharNumber: maskAadhaar(
            state?.AadhaarNumber.replace(/-/g, "")
          ),
        },
      });
      setisLoading(true);
      const route =
        state?.role === "Dist"
          ? "/kyc/Merchant-download-okyc"
          : "/kyc/Dealer-download-okyc";
      const SubmitOkycResponse = await axios.post(`${domain}${route}`, {
        ...okycdata,
        maskedAdharNumber: maskAadhaar(state?.AadhaarNumber),
        otp: otp.join(""),
        p1data: GESRecords(
          {
            ...state,
            maskedAadharNumber: maskAadhaar(
              state?.AadhaarNumber.replace(/-/g, "")
            ),
          },
          apiEN
        ),
      });

      console.log(SubmitOkycResponse);

      if (SubmitOkycResponse.status === 200) {
        console.log("----------------- Submit OTP response");
        navigate("/FinalScreenMechant");
        // console.log("response data ", response.data);
      } else {
        alert("There seems to be a problem . Please try again later");
      }
    } catch (error) {
      console.log(error);
      alert("error");
      throw error;
    } finally {
      setisLoading(false);
    }
  };
  return (
    <div className="DistFinalSet-company-info">
      <div className="DistFinalSet-company-info-container">
        <img
          src={process.env.PUBLIC_URL + "/img/svg/Logo.svg"}
          className="greattrlogo"
          alt="SVG Icon"
        />
        <p>6 / 6</p>
        <h3
          style={{
            padding: "2rem",
          }}
        >
          Final Step
        </h3>

        {!isOtpSent && (
          <p
            style={{ fontSize: 20, textAlign: "center", margin: "0 auto" }}
            className="sub-heading"
          >
            Enter the Captcha code below to get KYC process started
          </p>
        )}

        {isOtpSent && (
          <p
            style={{ fontSize: 20, textAlign: "center", margin: "0 auto" }}
            className="sub-heading"
          >
            Enter the OTP you just received on your mobile number linked to your
            aadhar number
          </p>
        )}
        <div
          style={{
            display: isOtpSent ? "none" : "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            {okycdata !== null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 10,
                  width: "100%",
                }}
              >
                <img
                  src={`data:image/png;base64,${okycdata?.encodedCaptcha}`}
                  alt="captcha"
                  style={{ width: 200, height: 100 }}
                />
                <button
                  style={{
                    borderRadius: "50%", // This makes it circular
                    width: "50px", // Set a fixed width
                    height: "50px", // Set a fixed height (same as width for a circle)
                    backgroundColor: "#ffffff",
                    display: "flex", // Use flexbox for centering
                    justifyContent: "center", // Center horizontally
                    alignSelf: "center", // Center vertically
                    border: "none", // Remove border if you don't want it
                    padding: 0, // Remove padding
                    cursor: "pointer", // Change cursor to pointer on hover
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/img/svg/reload.svg"}
                    alt="SVG Icon"
                    onClick={generateCaptcha}
                    width={40}
                    className="img-responsive"
                  />
                </button>
              </div>
            ) : (
              <Spinner animation="border" role="status">
                <span
                  style={{ width: 200, height: 500 }}
                  className="visually-hidden"
                >
                  Loading...
                </span>
              </Spinner>
            )}
          </div>

          <br />
          <TextInputWrapper
            type="text"
            value={captchaValue}
            onChange={(e) => setCaptchaValue(e)}
            placeholder="Enter Captcha Value"
            className="placeholder-font"
            required
          />
          <br />
          <button
            style={{ padding: 15, maxWidth: 400 }}
            className="continue-button"
            onClick={getOtp}
            // disabled={isOtpSent ? true : false}
          >
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Get OTP"
            )}
          </button>
        </div>

        {isOtpSent && (
          <div>
            <div
              className="form-group"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1.5rem",
                flex: 1,
                height: "10%",
                padding: 10,
                width: "100%",
              }}
            >
              {otp.map((data, index) => (
                <input
                  key={index}
                  className="otpstyle"
                  type="text"
                  name="otp"
                  maxLength="1"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            {otpError && <p className="error">{otpError}</p>}
            <p className="sub-heading-resend" onClick={ResendAadharOtp}>
              Resend OTP?
            </p>

            <br />
            <button
              style={{ padding: 15, maxWidth: 400 }}
              className="continue-button"
              onClick={submitOkycOtp}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DistFinalSet;
