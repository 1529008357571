import React, { useState, useEffect } from "react";
import "./CompanyInfoOperator.scss";
import greattrlogo from "./assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GESRecords } from "../Utilsx/Encrypt";
import axios from "axios";
import { Spinner } from "react-bootstrap";
const CompanyInfoOperator = () => {
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;
  const navigate = useNavigate();
  const location = useLocation();
  // const { state } = location;
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  console.log("state", state);

  const handleChange = (event) => {
    setSelectedBank(event.target.value);
  };
  const [isLoading, setisLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [MobileNumber, setMobileNumber] = useState(state?.MobileNumber);
  const [searchTerm, setSearchTerm] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [bankDetails, setBankDetails] = useState(null);
  const [BankAccountNumber, setBankAccountNumber] = useState("");
  const [error, setError] = useState(null);

  const fetchBankDetails = async () => {
    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${IFSCCode}`);
      setBankDetails(response.data);
      setSearchTerm(response.data.BANK);
      setSelectedBank(response.data.BANK);
      console.log("result", response.data);
    } catch (err) {
      console.log(
        "Error fetching bank details. Please check the IFSC code and try again."
      );
      setBankDetails(null);
    }
  };

  const validateIFSC = (code) => {
    // IFSC code format: 4 alphabets representing bank,
    // followed by 0 (zero), then 6 digit branch code
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return ifscRegex.test(code);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const bankNumberRegex = /^\d{9,18}$/;
    const IFSCCODERegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    console.log(MobileNumber);
    if (!selectedBank || selectedBank === "") {
      alert("Please select a Bank");
      return;
    }

    if (!IFSCCODERegex.test(IFSCCode)) {
      alert("Please enter valid Bank IFSC Code");
      return;
    }
    if (!bankNumberRegex.test(BankAccountNumber)) {
      alert("Please enter valid Bank Account Number");
      return;
    }

    const data = {
      BankName: selectedBank,
      IFSCCode: IFSCCode,
      MobileNumber: MobileNumber,
      BankAccountNumber: BankAccountNumber,
    };

    const EncrytedSubmitData = GESRecords(data, apiEN);
    try {
      setisLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/UpdateOperatorBankDetails`,
        EncrytedSubmitData
      );

      if (response.status === 200) {
        alert(response.data.message);

        navigate("/UploadDocs", {
          state: {
            MobileNumber: state.MobileNumber,
            ...state,
          },
        });
      }
    } catch (error) {
      console.log(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (validateIFSC(IFSCCode)) {
      fetchBankDetails();
    }
  }, [IFSCCode]);

  return (
    <div className="company-info">
      <div className="OperatorInfo-company-info-container">
        <img
          // className="moblogo"
          className="greattrlogo"
          src={greattrlogo}
          alt=""
        />
        <p>2 / 4</p>
        <h3 style={{ margin: 0, fontSize: 20 }}>Bank Account Details</h3>
        <h5 style={{ margin: 0, fontSize: 16 }}>
          Almost done! We need your bank information to facilitate smooth
          transactions.
        </h5>
        <h5 style={{ margin: 0, fontSize: 16 }}>
          This will enable you to receive payments and manage your finances
          efficiently on our platform.
        </h5>
        <form style={{ marginTop: 10 }} onSubmit={handleSubmit}>
          <div className="Operator-form-group">
            <label htmlFor="IFSCCode">IFSC Code</label>
            <input
              type="text"
              id="IFSCCode"
              name="IFSCCode"
              value={IFSCCode}
              placeholder="Enter IFSC Code"
              onChange={(e) => {
                setIFSCCode(e.target.value.toLocaleUpperCase());
                if (!validateIFSC(e.target.value.toLocaleUpperCase())) {
                  setError("Please enter correct IFSC code ");
                } else {
                  setError(null);
                }
              }}
              required
            />
            {error && <span className="error">{error}</span>}
          </div>
          <div className="Operator-form-group">
            <label htmlFor="agencyName">Bank Name</label>
            <input
              type="text"
              id="searchBank"
              name="searchBank"
              value={searchTerm}
              placeholder="Search for a bank"
              autoComplete="off"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm.length > 3 &&
              Object.keys(bankOptions)
                .filter((key) =>
                  bankOptions[key]
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((key) => (
                  <button
                    key={key}
                    value={key}
                    style={{
                      backgroundColor: "white",
                      backgroundColor:
                        selectedBank === bankOptions[key] ? "#1db954" : "white",
                      padding: 0,
                      marginBottom: 10,
                      color: "black",
                      textAlign: "left",
                      paddingLeft: 9,
                    }}
                    onClick={() => {
                      setSelectedBank(bankOptions[key]);
                      setSearchTerm(bankOptions[key]);
                    }}
                  >
                    {bankOptions[key]}
                  </button>
                ))}
          </div>

          <div className="Operator-form-group">
            <label htmlFor="BankAccountNumber">Bank Account Number</label>
            <input
              id="BankAccountNumber"
              name="BankAccountNumber"
              value={BankAccountNumber}
              placeholder="Enter Bank Account Number"
              onChange={(e) => setBankAccountNumber(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading} style={{ padding: 15 }}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const bankOptions = {
  AACX: "Akhand Anand Co.op Bank",
  ABBL: "AB Bank",
  ABCX: "Aurangabad District Central Co-operative Bank",
  ABDX: "Dr. Ambedkar Nagrik Sahakari Bank Mydt Gwalior",
  ABEX: "Andhra Bank Employees Co-operative Bank",
  ABHY: "Abhyudaya Co-operative Bank",
  ABNA: "Royal Bank of Scotland N.V.",
  ABPB: "Aditya Birla Idea Payments Bank",
  ABSB: "Abhinav Sahakari Bank",
  ABUX: "Abhinandan Urban Co-operative Bank Amravati",
  ACAX: "Assam Co-operative Apex Bank",
  ACBX: "Adarsh Co-operative Bank",
  ACCX: "Ace Co-operative Bank",
  ACKX: "Annasaheb Chougule Urban Co-operative Bank",
  ACOX: "Amarnath Co-operative Bank",
  ACUB: "Aryapuram Co-operative Urban Bank",
  ACUX: "Adarsh Co-operative Urban Bank",
  ADBX: "Ahmedabad District Co-operative Bank",
  ADCB: "Abu Dhabi Commercial Bank",
  ADCC: "Akola District Central Co-operative Bank",
  ADCX: "Shri Adinath Co-operative Bank",
  ADDX: "Adilabad District Co-operative Central Bank",
  AGCX: "Agrasen Co-operative Urban Bank",
  AGDX: "Agra District Co-operative Bank",
  AGRX: "Agroha Co-operative Urban Bank",
  AGSX: "Agrasen Nagari Sahakari Bank",
  AGUX: "Agartala Co-operative Urban Bank",
  AGVX: "Assam Gramin Vikash Bank",
  AHDC: "Ahmednagar District Central Co-operative Bank",
  AHMX: "Ahmednagar District Central Co-operative Bank",
  AHUX: "Ahilyadevi Urban Co-operative Bank Solapur",
  AIRP: "Airtel Payments Bank",
  AJAR: "Ajara Urban Co-operative Bank",
  AJHC: "Ambarnath Jai-hind Co-operative Bank",
  AJKB: "Akola Janata Commercial Co-operative Bank",
  AJMX: "Ajmer Central Co-operative Bank",
  AJNX: "Ajantha Urban Co-operative Bank",
  AJPX: "Ambajogai Peoples Co-operative Bank",
  AJSX: "Ambarnath Jai-hind Co-operative Bank",
  AJUX: "Ajara Urban Co-operative Bank",
  AKJB: "Akola Janata Commercial Co-operative Bank",
  AKKB: "Akkamahadevi Mahila Sahakari Bank Niyamit",
  AKMX: "Akola Merchant Co-operative Bank",
  AKOX: "Akola Urban Co-operative Bank",
  ALAX: "Alavi Co-operative Bank",
  ALIX: "Aligarh District Co-operative Bank",
  ALLA: "Allahabad Bank",
  ALLX: "Allahabad District Co-operative Bank",
  ALWX: "Alwar Central Co-operative Bank",
  AMAX: "Aman Sahakari Bank",
  AMBX: "Ambala Central Co-operative Bank",
  AMCB: "Ahmedabad Mercantile Co-operative Bank",
  AMCX: "Amritsar Central Co-operative Bank",
  AMDN: "Ahmednagar Merchants Co-operative Bank",
  AMMX: "Adarsh Mahila Mercantile Co-operative Bank",
  AMNX: "Astha Mahila Nagrik Sahakari Bank Maryadit",
  AMRX: "Amreli Jilla Madhyastha Sahakari Bank",
  AMSB: "Annasaheb Magar Sahakari Bank",
  AMSX: "Ambika Mahila Sahakari Bank",
  ANBX: "Amreli Nagarik Sahakari Bank",
  ANDB: "Andhra Bank",
  ANDX: "Andarsul Urban Co-operative Bank",
  ANMX: "Ahmednagar Merchant's Co-operative Bank",
  ANSX: "Andaman & Nicobar State Co-operative Bank",
  ANUX: "Anuradha Urban Co-operative Bank",
  ANZB: "Australia and New Zealand Banking Group",
  APBL: "Andhra Pradesh State Co-operative Bank",
  APCX: "Alappuzha District Co-operative Bank",
  APGB: "Andhra Pragathi Grameena Bank",
  APGV: "Andhra Pradesh Grameena Vikas Bank",
  APGX: "Andhra Pradesh Grameena Vikas Bank",
  APJX: "Ap Janata Co-operative Urban Bank",
  APMC: "A.P. Mahesh Co-operative Urban Bank",
  APMX: "Ap Mahajan's Co-operative Urban Bank",
  APNX: "Apani Sahakari Bank",
  APRR: "A.P. Raja Rajeswari Mahila Co-operative Urban Bank",
  APRX: "Arunachal Pradesh Rural Bank",
  APSX: "P. D. Patilsaheb Sahakari Bank",
  ARBL: "Arvind Sahakari Bank",
  ARCX: "Arunachal Pradesh State Co-operative Apex Bank",
  ARMX: "510 Army Base W/s Credit Co-operative Primary Bank",
  ARYX: "Gramin Bank of Aryavart",
  ASBL: "Apna Sahakari Bank",
  ASBX: "Ahmednagar Shahar Sahakari Bank Maryadit",
  ASHX: "Ashta People's Co-operative Bank",
  ASKX: "Arvind Sahakari Bank",
  ASNX: "Ashoknagar Co-operative Bank",
  ASOX: "Associate Co-operative Bank",
  ASSX: "Ashok Sahakari Bank",
  AUBL: "AU Small Finance Bank",
  AUBX: "Angul United Central Co-operative Bank",
  AUCB: "Almora Urban Co-operative Bank",
  AUCX: "Ajara Urban Co-operative Bank",
  AUGX: "Allahabad Up Gramin Bank",
  AURX: "Aurangabad District Central Co-operative Bank. Bihar",
  AVDX: "Amravati District Central Co-operative Bank",
  AWCX: "Alwaye Urban Co-operative Bank",
  AWUX: "Alwar Urban Co-operative Bank",
  AZAX: "Azad Co-operative Bank",
  AZPX: "Amravati Zilla Parishad Shikshak Sahakari Bank",
  AZSX: "Almora Zila d Bank",
  AZUX: "Azad Urban Co-operative Bank Hubli",
  BACB: "Bassein Catholic Co-operative Bank",
  BACX: "Bihar Awami Co-operative Bank",
  BADX: "Bankura District Central Co-operative Bank",
  BAGX: "Balangir District Central Co-operative Bank",
  BALB: "Bally Co-operative Bank",
  BALX: "Balasinor Nagarik Sahakari Bank",
  BANX: "Banaras Mercantile Co-operative Bank",
  BARA: "Baramati Sahakari Bank",
  BARB: "Bank of Baroda",
  BARC: "Barclays Bank",
  BARX: "Baroda City Co-operative Bank",
  BASX: "Shri Basaveshwar Sahakari Bank Nyt.bagalkot",
  BAUX: "Badagara Co-operative Urban Bank",
  BAVX: "Bavla Nagrik Sahakari Bank",
  BAWX: "Bhawanipatna Central Co-operative Bank",
  BBDX: "Birbhum District Central Co-operative Bank",
  BBKM: "Bank of Bahrein and Kuwait",
  BBLX: "Bhingar Urban Co-operative Bank",
  BBRX: "Bangalore  Bangalore Rural&ramanagara Dccb",
  BBSX: "Balasore Bhadrak Central Co-operative Bank",
  BBUX: "Bellad Bagewadi Urban Souharada Sahakari Bank Nyt",
  BBVX: "Bhabhar Vibhag Nagrik Sahakari Bank",
  BCBM: "Bharat Co-operative Bank",
  BCBX: "Bantra Co-operative Bank",
  BCCB: "Bangalore City Co-operative Bank",
  BCCX: "Bathinda Central Co-operative Bank",
  BCEX: "Banswara Central Co-operative Bank",
  BCEY: "Bank of Ceylon",
  BCOX: "Bapuji Co-operative Bank",
  BCUB: "Bicholim Urban Co-operative Bank",
  BCUX: "Baghat Urban Co-operative Bank",
  BDBB: "Bahraich District Co-operative Bank",
  BDBL: "Bandhan Bank",
  BDBX: "Bellary District Co-operative Central Bank",
  BDCX: "Bijapur District Central Co-operative Bank",
  BDDX: "Bhadradri Co-operative Urban Bank",
  BDIX: "Bhandara District Central Co-operative Bank",
  BDNX: "Bhind Nagrik Sahakari Bank Mydt",
  BDOX: "Banda District Co-operative Bank",
  BDUX: "Banda Urban Co-operative Bank",
  BEDX: "Beed District Central Co-operative Bank",
  BELX: "Belgaum District Central Co-operative Bank",
  BERX: "Berhampore Co-operative Central Bank",
  BFUX: "Bhagyodaya Friends Urban Co-operative Bank",
  BGBX: "Dakshin Bihar Gramin Bank",
  BGCX: "Balageria Central Co-operative Bank",
  BGGX: "Baroda Gujarat Gramin Bank",
  BGUX: "Begusarai Central Co-operative Bank",
  BGVX: "Bangiya Gramin Vikash Bank",
  BHAX: "Bhagyodaya Co-operative Bank",
  BHBX: "Bhilai Nagarik Sahakari Bank Maryadit",
  BHCX: "Bhuj Commercial Co-operative Bank",
  BHDX: "Bhadohi Urban Co-operative Bank Gyanpur",
  BHEX: "Bhel Employees Co-operative Bank",
  BHGX: "Bhagalpur Central Co-operative Bank",
  BHIX: "Bhilwara Mahila Urban Co-operative Bank",
  BHJX: "Bhuj Mercentile Co-operative Bank",
  BHMX: "Brahmadeodada Mane Sahakari Bank Solapur",
  BHOX: "Bhopal Co-operative Central Bank",
  BHRX: "Bharatpur Central Co-operative Bank",
  BHSX: "Bharati Sahakari Bank",
  BHTX: "Bhatkal Urban Co-operative Bank",
  BHUX: "Bhilwara Urban Co-operative Bank",
  BHWX: "Bhiwani Central Co-operative Bank Bhiwani",
  BJUX: "Bijnor Urban Co-operative Bank",
  BKCX: "Bhavasara Kshatriya Co-operative Bank",
  BKDN: "Dena Bank",
  BKDX: "Banaskantha District Central Co-operative Bank",
  BKID: "Bank of India",
  BKSX: "Baran Kendriya Sahakari Bank Baran",
  BLGX: "Shree Basaveshwar Co-operative Bank",
  BMBL: "Bharatiya Mahila Bank",
  BMCB: "Bombay Mercantile Co-operative Bank",
  BMCX: "Barmer Central Co-operative Bank",
  BMPX: "Banaskantha Mercantile Co-operative Bank",
  BMSX: "Bhagyalakshmi Mahila Sahakari Bank",
  BNBX: "Betul Nagrik Sahakari Bank Mydt",
  BNCX: "Bhatpara Naihati Co-operative Bank",
  BNKX: "Banki Central Co-operative Bank",
  BNPA: "BNP Paribas Bank",
  BNSB: "Bhagini Nivedita Sahakari Bank Pune",
  BNSX: "Bhagini Nivedita Sahakari Bank",
  BOCX: "Boudh Co-operative Central Bank",
  BODX: "Bagalkot District Central Co-operative Bank",
  BOFA: "Bank of America",
  BORX: "Boral Union Co-operative Bank",
  BOTM: "MUFG Bank",
  BOTX: "Shree Botad Mercantile Co-operative Bank",
  BPCX: "Bhadgaon People's Co-operative Bank",
  BPSX: "Bilagi Pattana Sahakari Bank Niyamit",
  BRCX: "Bhavana Rishi Co-operative Urban Bank",
  BRDX: "Baroda Central Co-operative Bank",
  BRGX: "Baroda Rajasthan Kshetriya Gramin Bank",
  BRMX: "Bramhapuri Urban Co-operative Bank",
  BRSX: "Baran Nagrik Bank",
  BRUX: "Bharuch District Central Co-operative Bank Bharuc",
  BSBX: "Baramati Sahakari Bank",
  BSCX: "Baidyabati Sheoraphuli Co-operative Bank",
  BTCX: "Balitikuri Co-operative Bank",
  BTUX: "Balotra Urban Co-operative Bank",
  BUBX: "Bhandara Urban Co-operative Bank",
  BUCL: "Belur Urban Co-operative Bank",
  BUCX: "Beawar Urban Co-operative Bank",
  BUGX: "Baroda Uttar Pradesh Gramin Bank",
  BUNX: "Bundi Central Co-operative Bank",
  BURX: "Burdwan Central Co-operative Bank",
  BUSX: "Business Co-operative Bank",
  BUZX: "Badaun Zila d Bank",
  BVNX: "Bhavnagar District Co-operative Bank",
  BVSX: "Bhavani Sahakari Bank",
  BWCX: "Brahmawart Commercial Co-operative Bank",
  CALX: "Calicut Co-operative Urban Bank",
  CBHX: "Central Co-operative Bank  Bhilwara",
  CBIN: "Central Bank of India",
  CCBL: "Citizen Credit Co-operative Bank",
  CCBX: "Citizen Co-operative Bank",
  CCCX: "Chennai Central Co-operative Bank",
  CCMX: "Chanasma Commercial Co-operative Bank",
  CCOB: "City Co-operative Bank",
  CCUX: "Chengelpattu Co-operative Urban Bank",
  CDCX: "Cuddalore District Central Co-operative Bank",
  CEBX: "Central Co-operative Bank Bikaner",
  CGBX: "Chhattisgarh Rajya Gramin Bank",
  CGGX: "Chaitanya Godavari Grameena Bank",
  CHAS: "JP Morgan Chase Bank NA",
  CHAX: "Chamoli Zila d Bank",
  CHBX: "Chamba Urban Co-operative Bank Chamba",
  CHCX: "Churu Central Co-operative Bank",
  CHDX: "Chittoor District Co-operative Central Bank",
  CHIX: "Chikhli Urban Co-operative Bank",
  CHKX: "Chittorgarh Kendriya Sahakari Bank",
  CHPX: "Chopda Peoples Co-operative Bank",
  CHRX: "Cherpalcheri Co-operative Urban Bank",
  CHSX: "Chandigarh State Co-operative Bank",
  CHTX: "Chitnavispura Sahakari Bank",
  CIDX: "Chikmagalur District Central Co-operative Bank",
  CITI: "CITI Bank",
  CITX: "Chitradurga District Co-operative Central Bank",
  CIUB: "City Union Bank",
  CJAX: "Citizens' Co-operative Bank Jammu",
  CJMX: "Karnataka Mahila Sahakari Bank",
  CLBL: "Capital Small Finance Bank",
  CMCB: "Colour Merchant's Co-operative Bank",
  CMCX: "Commercial Co-operative Bank",
  CMDX: "Coimbatore District Central Co-operative Bank",
  CMLX: "Commercial Co-operative Bank",
  CMPX: "Central Madhya Pradesh Gramin Bank",
  CMSV: "Chaitanya Mahila Sahakari Bank, Vijayapur",
  CNRB: "Canara Bank",
  CNSX: "Chembur Nagarik Sahakari Bank",
  COAS: "Coastal Local Area Bank",
  COCX: "Co-operative City Bank",
  COLX: "Coastal Local Area Bank",
  COMX: "Co-operative Bank of Mehsana",
  CONX: "Contai Co-operative Bank",
  CORP: "Corporation Bank",
  COSB: "Cosmos Co-operative Bank",
  CPDX: "Chandrapur District Central Co-operative Bank",
  CPSN: "Chikmagalur Pattana Sahakara Bank Niyamitha",
  CRBX: "Central Co-operative Bank Ara",
  CRES: "Credit Suisse AG",
  CRGB: "Chhattisgarh Rajya Gramin Bank",
  CRLY: "Credit Agricole Corporate and Investment Bank",
  CRSX: "Chhattisgarh Rajya Sahakari Bank Mydt",
  CRUB: "Shri Chhatrapati Rajashri Shahu Urban Co-operative Bank",
  CSBK: "Catholic Syrian Bank",
  CSBX: "Chartered Sahakari Bank Niyamitha",
  CTBA: "Commonwealth Bank of Australia",
  CTBX: "Citizens Co-operative Bank",
  CTCB: "Chinatrust Commercial Bank",
  CTOX: "Central Co-operative Bank Tonk",
  CTUX: "Chittorgarh Urban Co-operative Bank",
  CUBX: "Catholic Co-operative Urban Bank",
  CUCX: "Chiplun Urban Co-operative Bank",
  CURX: "Citizens Urban Co-operative Bank",
  CUTX: "Cuttack Central Co-operative Bank",
  CZCX: "Citizen Co-operative Bank",
  CZUX: "Churu Zila Urban Co-operative Bank",
  DAAX: "Defence Accounts Co-operative Bank",
  DAHX: "Dahod Mercantile Co-operative Bank",
  DAUX: "Dausa Urban Co-operative Bank",
  DBAX: "Dr Babasaheb Ambedkar Sahakari Bank Nasik",
  DBSS: "Development Bank of Singapore",
  DCBL: "DCB Bank",
  DCBX: "Dindigul Central Co-operative Bank",
  DCCX: "District Co-operative Central Bank Mahabubnagar",
  DCDX: "District Central Co-operative Bank Elluru",
  DCEX: "District Central Co-operative Bank Khammam",
  DCKX: "District Co-operative Central Bank Kurnool",
  DCMX: "District Co-operative Bank Mainpuri",
  DCNX: "Deccan Co-operative Urban Bank",
  DCPX: "District Co-operative Bank Pilibhit",
  DCSX: "District Co-operative Bank Shahjahanpur",
  DCTX: "District Co-operative Bank Teliyabagh",
  DCUB: "Darussalam Co-operative Urban Bank",
  DCUX: "Darussalam Co-operative Urban Bank",
  DDBX: "Dharmapuri District Central Co-operative Bank",
  DDCX: "Darjeeling District Central Co-operative Bank",
  DDDX: "Dakshin Dinajpur District Central Co-operative Bank",
  DDHX: "Dehradun District Co-operative Bank",
  DEGX: "Dena Gujarat Gramin Bank",
  DENS: "Delhi Nagrik Sehkari Bank",
  DEOB: "Deogiri Nagari Sahakari Bank Aurangabad",
  DEOX: "Deogiri Sahakari Bank Aurangabad",
  DEUT: "Deutsche Bank",
  DEUX: "Devika Urban Co-operative Bank",
  DEVX: "Development Co-operative Bank Kanpur",
  DGBX: "Telangana Grameena Bank",
  DHBX: "Dhanbad Central Co-operative Bank",
  DHKX: "Dhakuria Co-operative Bank",
  DHUX: "Dahod Urban Co-operative Bank",
  DIBX: "District Co-operative Bank Barabanki",
  DICG: "Deposit Insurance and Credit Guarantee Corporation",
  DICX: "District Co-operative Central Bank Visakhapatnam",
  DISX: "District Co-operative Bank, Sitapur",
  DIUX: "Dilip Urban Co-operative Bank",
  DJCX: "Deoghar Jamtara Central Co-operative Bank Deoghar",
  DKCL: "Deoria Kasia District Co-operative Bank",
  DKSX: "Dausa Kendriya Bank",
  DLSC: "Delhi State Co-operative Bank",
  DLXB: "Dhanlaxmi Bank",
  DMCB: "Deccan Merchants Co-operative Bank",
  DMCX: "Durgapur Mahila Co-operative Bank",
  DMKB: "Dattatraya Maharaj Kalambe Jaoli Sahakari Bank",
  DMKJ: "Dmk Jaoli Bank",
  DNDC: "Dhule And Nandurbar District Central Co-operative Bank",
  DNSB: "Dombivli Nagari Sahakari Bank",
  DNSX: "Deendayal Nagari Sahakari Bank",
  DOBX: "Dapoli Urban Co-operative Bank",
  DOHB: "Doha Bank QSC",
  DRGX: "Durg Rajnandgaon Gramin Bank",
  DSAX: "District Central Co-operative Bank, Supaul",
  DSBX: "Daivadnya Sahakara Bank Niyamit",
  DSCB: "Delhi State Co-operative Bank",
  DSHX: "District Co-operative Bank Saharanpur",
  DSPX: "Durgapur Steel Peoples' Co-operative Bank",
  DSUX: "Dharamvir Sambhaji Urban Co-operative Bank",
  DTCX: "District Co-operative Bank Rae Bareli",
  DTPX: "District Co-operative Bank Pratapgarh",
  DUCX: "Daund Urban Co-operative Bank",
  DUMX: "Dumka Central Co-operative Bank",
  DUNX: "Dungarpur Central Co-operative Bank",
  DURG: "Durgapur Steel Peoples Co-operative Bank",
  DVDX: "Davanagere District Central Co-operative Bank",
  DYPX: "D.y.patil Sahakari Bank Kolhapur",
  EBIL: "Emirates NBD Bank",
  ECBL: "Excellent Co-operative Bank",
  EDBX: "Ellaquai Dehati Bank",
  EDCX: "Erode District Central Co-operative Bank",
  EDSX: "Ernakulam District Co-operative Bank",
  EIBI: "Export Import Bank of India",
  ESAF: "ESAF Small Finance Bank",
  ESFB: "Equitas Small Finance Bank",
  ESMF: "Esaf Small Finance Bank",
  ETCX: "Etawah District Co-operative Bank Etwah",
  ETDX: "Etah District Co-operative Bank",
  EUCX: "Etah Urban Co-operative Bank",
  EWCX: "Etawah Urban Co-operative Bank Etawah",
  FCBX: "Fazilka Central Co-operative. Bank",
  FCCX: "Faridkot Central Co-operative Bank",
  FCOX: "District Co-operative Bank Faizabad",
  FDFX: "Farrukhabad District Co-operative Bank Fatehgarh",
  FDRL: "Federal Bank",
  FEKX: "Feroke Co-operative Bank",
  FGCB: "Fingrowth Co-operative Bank",
  FINF: "Fincare Small Finance Bank ",
  FINO: "Fino Payments Bank",
  FINX: "Financial Co-operative Bank",
  FIRN: "Firstrand Bank",
  FIRX: "Firstrand Bank",
  FMCX: "Faiz Mercantile Co-operative Bank, Nasik",
  FRIX: "Faridabad Central Co-operative Bank",
  FSCX: "Fatehgrah Sahib Central Co-operative Bank",
  FSFB: "Fincare Small Finance Bank",
  FZCX: "Ferozepur Central Co-operative. Bank",
  FZSX: "Firozabad Zila d Bank",
  GACX: "Gujarat Ambuja Co-operative Bank",
  GADX: "Gadhinglaj Urban Co-operative Bank",
  GAMX: "Ganga Mercantile Urban Co-operative Bank",
  GANX: "Gandhidham Co-operative Bank",
  GBCB: "Greater Bombay Co-operative Bank",
  GCBX: "Guruvayur Co-operative Urban Bank",
  GCCX: "Gurdaspur Central Co-operative Bank",
  GCUL: "Gauhati Co-operative Urban Bank",
  GCUX: "Gayatri Co-operative Urban Bank",
  GDCB: "Gadchiroli District Central Co-operative Bank",
  GDCX: "Guntur District Co-operative Central Bank",
  GDDX: "Giridih Central Co-operative Bank",
  GDUX: "Godhra Urban Co-operative Bank",
  GGBK: "Gurgaon Gramin Bank",
  GGCX: "Gopalganj Central Co-operative Bank",
  GHPX: "Ghatal Peoples' Co-operative Bank",
  GKNX: "Ganganagar Kendriya Sahakari Bank",
  GMBX: "Gandhidham Mercantile Co-operative Bank",
  GMCX: "Gujarat Mercantile Co-operative Bank",
  GMUX: "Gulshan Mercantile Urban Co-operative Bank",
  GNCX: "Gandhi Co-operative Urban Bank",
  GNSX: "Gozaria Nagrik Sahakari Bank",
  GODX: "Godavari Urban Co-operative Bank Nashik",
  GOSX: "Gondal Nagarik Sahakari Bank",
  GPCX: "Gandevi People's Co-operative Bank",
  GPOX: "General Post Office",
  GRAX: "Grain Merchants' Co-operative Bank",
  GSBL: "Goa State Co-operative Bank",
  GSBX: "Gandhibag Sahakari Bank  Nagpur",
  GSCB: "Gujarat State Co-operative Bank",
  GSCX: "Gumla Simdega Central Co-operative Bank",
  GSSX: "Guardian Souharda Sahakari Bank Niyamita",
  GTCX: "George Town Co-operative Bank",
  GUBX: "Godavari Urban Co-operative Bank",
  GUCX: "Goa Urban Co-operative Bank",
  GUNX: "Guntur Co-operative Urban Bank",
  GUOX: "Gurgaon Central Co-operative Bank",
  HAMX: "Hamirpur District Co-operative Bank",
  HANX: "Hanumangarh Kendriya Sahakari Bank",
  HARC: "Haryana State Co-operative Apex Bank",
  HCBL: "HASTI Co-operative Bank",
  HCBX: "Hasti Co-operative Bank",
  HCCX: "Hazaribag Central Co-operative Bank",
  HCLX: "HCBL Co-operative Bank",
  HDCL: "Hardoi District Co-operative Bank",
  HDCX: "Hyderabad District Co-operative Bank",
  HDFC: "HDFC Bank",
  HGBX: "Sarva Haryana Gramin Bank",
  HINX: "Hindu Co-operative Bank",
  HISX: "Hisar Central Co-operative Bank Hisar",
  HMBX: "Himachal Pradesh Gramin Bank",
  HMNX: "Himatnagar Nagarik Sahakari Bank",
  HOCX: "Hoshiarpur Central Co-operative Bank",
  HOOX: "Hooghly District Central Co-operative Bank",
  HPCX: "Shree Parswanath Co-operative Bank",
  HPSC: "Himachal Pradesh State Co-operative Bank",
  HPSX: "Himachal Pradesh State Co-operative Bank",
  HSBC: "Hongkong & Shanghai Banking Corporation",
  HSBM: "Haveli Sahakari Bank",
  HSBX: "Harihareshwar Sahakari Bank",
  HSCX: "Haryana State Co-operative Apex Bank",
  HSDX: "Hassan District Co-operative Central Bank",
  HSSX: "Hindustan Shipyard Staff Co-operative Bank",
  HUBX: "Hubli Urban Co-operative Bank",
  HUCB: "Hadagali Urban Co-operative Bank",
  HUCH: "Hanamasagar Urban Co-operative Bank",
  HUCX: "Hissar Urban Co-operative Bank",
  HUSB: "Hutatma Sahakari Bank",
  HUTX: "Hutatma Sahakari Bank",
  HVBK: "Woori Bank",
  IBBK: "PT Bank Maybank Indonesia TBK",
  IBKL: "IDBI",
  IBKO: "Industrial Bank of Korea",
  ICBK: "Industrial and Commercial Bank of China",
  ICBL: "Industrial Co-operative Bank",
  ICHX: "Ichalkaranji Merchants Co-operative Bank",
  ICIC: "ICICI Bank",
  ICLL: "Indian Clearing Corporation",
  ICMX: "Indore Cloth Market Co-operative Bank",
  IDFB: "IDFC FIRST Bank",
  IDIB: "Indian Bank",
  IDUK: "Idukki District Co-operative Bank",
  IDUX: "Idukki District Co-operative Bank",
  ILCB: "Ilkal Co-operative Bank",
  IMCX: "Imperial Urban Co-operative Bank Jalandhar",
  IMPX: "Imphal Urban Co-operative Bank",
  INCX: "Indapur Urban Co-operative Bank",
  INDB: "Indusind Bank",
  INDX: "Independence Co-operative Bank",
  IOBA: "Indian Overseas Bank",
  IPCX: "Indore Premier Co-operative Bank",
  IPOS: "India Post Payments Bank",
  IPPB: "India Post Payments Bank",
  IPSX: "Indore Paraspar Sahakari Bank",
  ISBX: "Indraprastha Sehkari Bank",
  ISMX: "Indore Swayam Mahila Co-operative Bank",
  ITBL: "Irinjalakuda Town Co-operative Bank",
  ITCX: "Irinjalakuda Town Co-operative Bank",
  ITDX: "Income Tax Dept Co-operative Bank",
  IUCB: "Integral Urban Co-operative Bank",
  IUCX: "Imperial Urban Co-operative Bank",
  IXXX: "Indrayani Co-operative Bank",
  JACX: "Jain Co-operative Bank",
  JAKA: "Jammu and Kashmir Bank",
  JALX: "Janalaxmi Co-operative Bank",
  JAMX: "Jamia Co-operative Bank",
  JANA: "Janaseva Sahakari Bank, Pune",
  JANX: "Janata Co-operative Bank",
  JASB: "Janaseva Sahakari Bank (Borivli)",
  JASX: "Janatha Seva Co-operative Bank",
  JAUX: "Jaihind Urban Co-operative Bank",
  JBHX: "Jila Sahakari Kendriya Bank Maryadit Bhind",
  JBIX: "Jila d Kendriya Bank Maryadit Bilaspur",
  JBMX: "Jila Sahakari Kendriya Bank Maryadit Sagar",
  JCBX: "Janata Co-operative Bank Malegaon.",
  JCCB: "Jaipur Central Co-operative Bank",
  JCCX: "Jalandhar Central Co-operative Bank",
  JCDX: "Jamnagar District Co-operative Bank",
  JCHX: "Jila Sahakari Kendriya Bank Mydt Chhatarpur",
  JCPX: "Janata Co-operative Bank",
  JCUX: "Jagruti Co-operative Urban Bank",
  JDCX: "Jalaun District Co-operative Bank",
  JDEX: "Jila Sahakari Kendriya Bank Mydtt Dewas",
  JGBX: "Jharkand Gramin Bank",
  JGCX: "Jogindra Central Co-operative Bank",
  JGWX: "Jila Sahakari Bank Mydt. Gwalior",
  JHAX: "Jharneshwar Nagrik Sahakari Bank Maryadit",
  JHSX: "Jila Sahakari Kendriya Bank Mydt Hoshangabad",
  JHUX: "Jhunjhunu Kenddriya Sahakari Bak",
  JIBX: "Jila Sahakari Kendriya Bank Maryadit Balaghat",
  JICX: "Jila Sahakari Kendriya Bank Maryadit Chhindwara",
  JIDX: "Jila d Kendriya Bank Mydt Damoh",
  JIGX: "Jila Sahakari Kendriya Bank Mydt Guna",
  JIKX: "Jila Sahakari Kendariya Bank Mydt Khandwa",
  JIMX: "Jila Sahakari Kendriya Bank Mydt Mandla",
  JINX: "Jind Central Co-operative Bank",
  JIOP: "Jio Payments Bank",
  JIOX: "Jila Sahakari Kendriya Bank Mydt Shahdol",
  JIRX: "Jila Sahakari Kendriya Bank Maryadit Rajgarh",
  JISX: "Jila Sahakari Kendriya Bank Maryadit Sidhi",
  JIVX: "Jivaji Sahakari Bank  Ichalkaranji",
  JJCX: "Jhajjar Central Co-operative Bank",
  JJHX: "Jila d Kendriya Bank Mydt Jhabua",
  JJSB: "Jalgaon Janata Bank",
  JKAX: "Jila Sahakari Kendriya Bank Mydt Ambikapur",
  JKCX: "Janakalyan Co-operative Bank Nashik",
  JKDX: "Jila Sahakari Kendriya Bank Mydt. Jabalpur",
  JKEX: "Jhalawar Kendriya Bank",
  JKHX: "Jila Kendriya Bank Maryadit Khargone",
  JKMX: "Jila Sahakari Kendriya Bank Maryadit Jagdalpur",
  JKRX: "Jilla Sahakari Kendriya Bank Mydt Raisen",
  JKSX: "J&k State Co-operative Bank",
  JLCX: "Jalore Central Co-operative Bank Jalore",
  JLDX: "Jalna District Central Co-operative Bank",
  JLNX: "Jalna Peoples Co-operative Bank Jalna",
  JLSX: "Jila Sahakari Kendriya Bank Mydt Vidisha",
  JLWX: "Jhalawar Nagrik Sahakari Bank",
  JMAX: "Jila Sahakari Kendriya Bank Mydt Mandsaur",
  JMBX: "Jila Sahakari Kendriya Bank Maryadit Betul",
  JMCX: "Jalna Merchants Co-operative Bank",
  JMDX: "Jila d Kendriya Bank Mydt Datia",
  JMHX: "Jamshedpur Urban Co-operative Bank",
  JMMX: "Jammu Central Co-operative Bank",
  JMOX: "Jila Sahakari Kendriya Bank Mydt Morena",
  JMPX: "Jamnagar Peoples Co-operative Bank",
  JMSX: "Jijamata Mahila Sahakari Bank",
  JMYX: "Jila Sahakari Kendriya Bank Mydt Durg",
  JNAX: "Jila Sahakari Kendriya Bank Mydt Narsinghpur",
  JNDX: "Junagadh Jilla Sahakari Bank",
  JNSX: "Janseva Nagari Sahakari Bank Marydit",
  JODX: "Jodhpur Central Co-operative Bank",
  JONX: "Jodhpur Nagrik Sahakari Bank",
  JOWX: "Jowai Co-operative Urban Bank",
  JPAX: "Jila Sahakari Kendriya Bank Mydt Panna",
  JPCB: "Jalgaon Peoples Co-operative Bank",
  JPCX: "Jalpaiguri Central Co-operative Bank",
  JRAX: "Jila Sahakari Kendriya Bank Mydt Ratlam",
  JRKX: "Jila Sahakari Kendriya Bank Mydt Ujjain",
  JRNX: "Jila Sahakari Kendriya Bank Maryadit Rajnandgaon",
  JRSX: "Jalore Nagrik Sahakari Bank",
  JSAB: "Jain Sahakari Bank",
  JSAX: "Janata Sahakari Bank Ajara",
  JSBL: "Janakalyan Sahakari Bank",
  JSBP: "Janata Sahakari Bank (Pune)",
  JSBX: "Janaseva Co-operative Bank (Nashik)",
  JSCX: "Jharkhand State Co-operative Bank",
  JSDX: "Jila Sahakari Kendriya Bank Mydt Dhar",
  JSEX: "Jila Sahakari Kendriya Bank Mydt Sehore",
  JSFB: "Jana Small Finance Bank",
  JSHX: "Jila Sahakari Kendriya Bank Mydt Shajapur",
  JSKX: "Jila Sahakari Kendriya Bank Maryadit Raipur",
  JSMX: "Janata Sahakari Bank Amravati",
  JSOX: "Jila Sahakari Kendriya Bank Myt Seoni",
  JSRX: "Jila Sahakari Kendriya Bank Mydt Rewa",
  JSTX: "Jila Sahakari Kendriya Bank Mydt Satna",
  JSVX: "Jila Sahakari Kendriya Bank Maryadit Shivpuri",
  JSWX: "Jansewa Urban Co-operative Bank",
  JTIX: "Jila Sahakari Kendriya Bank Mydt Tikamgarh",
  JTSC: "Janatha Seva Co-operative Bank",
  JTSX: "Jugalkishor Tapdiya Shree Mahesh Ucb Aurangabadltd",
  JUCX: "Junagadh Commercial Co-operative Bank",
  JUSX: "Jaysingpur Udgaon Sahakari Bank Jaysingpur",
  JVCX: "Jivan Commercial Co-operative Bank",
  JXXX: "Jampeta Co-Operative Urban Bank",
  KAAX: "Kadappa District Co-operative Central Bank",
  KACE: "Kangra Central Co-operative Bank",
  KACX: "Kachchh District Central Co-operative Bank",
  KADX: "Kasaragod District Co-operative Bank",
  KAGX: "Kagal Co-operative Bank Kagal",
  KAIJ: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank",
  KALX: "Kalna Town Credit Co-operative Bank",
  KAMX: "Kamala Co-operative Bank Solapur",
  KANG: "Kangra Co-operative Bank",
  KANX: "Kanara District Central Co-operative Bank",
  KARB: "Karnataka Bank",
  KARX: "Kaira District Central Co-operative Bank",
  KASX: "Kashmir Mercantile Co-operative Bank",
  KATX: "Kattappana Urban Co-operative Bank",
  KAYX: "Kanyakumari District Central Co-operative Bank",
  KBCX: "Kanakamahalakshmi Co-operative Bank",
  KBHB: "KEB Hana Bank",
  KBKB: "Kookmin Bank",
  KBNX: "Khambhat Nagarik Sahakari Bank",
  KBSX: "Krishna Bhima Samruddhi Local Area Bank",
  KCBL: "Kapol Co-operative Bank",
  KCBX: "Kutch Co-operative Bank",
  KCCB: "Kalupur Commercial Co-operative Bank",
  KCCX: "Kancheepuram Central Co-operative Bank",
  KCDX: "Karnatak Central Co-operative Bank Dharwad",
  KCEX: "Kota Central Co-operative Bank Kota",
  KCOB: "Kangra Co-operative Bank",
  KCUB: "Khattri Co-operative Urban Bank",
  KCUX: "Kannur Co-operative Urban Bank",
  KDBX: "Karimnagar District Co-operative Central Bank",
  KDCB: "Kozhikode District Co-operative Bank",
  KDCX: "Kozhikode District Co-operative Bank",
  KDIX: "Shree Kadi Nagarik Sahakari Bank",
  KDNX: "Kodinar Nagrik Sahakari Bank",
  KDUX: "Kodagu District Co-operative Central Bank",
  KEJX: "Keonjhar Central Co-operative Bank",
  KEMX: "Kerala Mercantile Co-operative Bank",
  KESX: "Keshav Sehkari Bank",
  KGBX: "Kaveri Grameena Bank",
  KGDX: "Khagaria District Central Co-operative Bank",
  KGRB: "Kaveri Grameena Bank",
  KGSX: "Kashi Gomti Samyut Gramin Bank",
  KHAX: "Khalilabad Nagar d Bank",
  KHCX: "Khardah Co-operative Bank",
  KHDX: "Kheda People's Co-operative Bank",
  KHNX: "Kohinoor Sahakari Bank Ichalkaranji",
  KHTX: "Khurda Central Co-operative Bank",
  KHUX: "Khamgaon Urban Co-operative Bank",
  KICX: "Kaithal Central Co-operative Bank",
  KJSB: "Kalyan Janata Sahakari Bank",
  KJSX: "Karad Janata Sahakari Bank",
  KKBK: "Kotak Mahindra Bank",
  KKMX: "Kankaria Mainagar Nagrik Sahakari Bank",
  KKSX: "Kumbhi Kasari Bank Kuditre",
  KLGB: "Kerala Gramin Bank",
  KLMX: "Kollam District Co-operative Bank",
  KMCB: "Kokan Mercantile Co-operative Bank",
  KMCX: "Krishna Mercantile Co-operative Bank",
  KMNX: "Kota Mahila Nagrik Sahakari Bank",
  KMSX: "Kolhapur Mahila Sahakari Bank",
  KNBX: "Kalol Nagarik Sahakari Bank",
  KNCX: "Karnal Central Co-operative Bank",
  KNNX: "Sri Kannikaparameswari Co-operative Bank",
  KNPX: "Karnala Nagari Sahakari Bank Panvel",
  KNSB: "Kurla Nagarik Sahakari Bank",
  KNSX: "Kisan Nagari Sahakari Bank Maryadit Parbhani",
  KOBX: "Kopargaon Peoples Co-operative Bank",
  KOCX: "Konoklota Mahila Urban Co-operative Bank",
  KODX: "Kodungallur Town Co-operative Bank",
  KOEX: "KEB Hana Bank",
  KOLH: "Kolhapur Urban Co-operative Bank",
  KORX: "Koraput Central Co-operative Bank",
  KOSX: "Kota Nagrik d Bank Kota",
  KOTX: "Kodinar Taluka Co-operative Banking Union",
  KOYX: "Koylanchal Urban Co-operative Bank",
  KPCX: "Kolhapur District Central Co-operative Bank",
  KRCX: "Kolar And Chickballapur Dt Co-operative Central Bank",
  KRDX: "Krishna District Co-operative Bank",
  KRIX: "Krishnagar City Co-operative Bank",
  KRMX: "Karamana Co-operative Urban Bank",
  KRNX: "Karnavati Co-operative Bank",
  KRTH: "Krungthai Bank",
  KRTX: "Kranthi Co-operative Urban Bank",
  KSBK: "Kerala State Co-operative Bank",
  KSBX: "Krishna Sahakari Bank Rethare Bk",
  KSCB: "Karnataka State Co-operative Apex Bank",
  KSCX: "Kerala State Co-operative Bank",
  KSMX: "Kosamba Mercantile Co-operative Bank",
  KSNX: "Koteshwara Sahakari Bank Niyamitha",
  KSTX: "Kasaragod Co-operative Town Bank No 970",
  KSUX: "Kashipur Urban Co-operative Bank",
  KTBX: "Kottayam District Co-operative Bank",
  KTCX: "Kapurthala Central Co-operative Bank",
  KTDX: "Katihar District Central Co-operative Bank",
  KTTX: "Tumkur District Central Bank",
  KUBX: "Kaduthuruthy Urban Co-operative Bank",
  KUCB: "Karad Urban Co-operative Bank",
  KUCX: "Kolhapur Urban Co-operative Bank",
  KUKX: "Kukarwada Nagarik Sahakari Bank",
  KULX: "Kodoli Urban Co-operative Bank Kodoli",
  KUMX: "Kumbakonam Central Co-operative Bank",
  KUNS: "Kurmanchal Nagar Sahakari Bank",
  KUOB: "Konark Urban Co-operative Bank",
  KURX: "Kurukshetra Central Co-operative Bank",
  KUTX: "Kuttiady Co-operative Urban Bank",
  KVBL: "Karur Vysya Bank",
  KVCX: "Kavita Urban Co-operative Bank",
  KVGB: "Karnataka Vikas Grameena Bank",
  KYDX: "Kalaburagi and Yadgir District Co-operative Central Bank",
  LACX: "Laxmi Co-operative Bank Solapur",
  LATX: "Latur Urban Co-operative Bank Latur",
  LAVB: "Laxmi Vilas Bank",
  LBMX: "Laxmibai Mahila Nagrik Sahakari Bank Maradit",
  LCBX: "Lalbaug Co-operative Bank",
  LCCX: "Ludhiana Central Co-operative Bank",
  LDCX: "Latur District Central Co-operative Bank",
  LDPX: "Loknete Dattaji Patil Sahakari Bank",
  LDRX: "Langpi Dehangi Rural Bank",
  LECX: "LIC Employees Co-operative Bank",
  LICB: "Liluah Co-operative Bank",
  LKBL: "Lord Krishna Bank",
  LKCX: "Little Kancheepuram Co-operative Urban Bank",
  LKHX: "Lakhimpur Urban Co-operative Bank",
  LKMX: "Lokmangal Co-operative Bank Solapur",
  LMNX: "Laxmi Mahila Nagrik Sahakari Bank Maryadit",
  LNSX: "Lunawada Nagarik Sahakari Bank",
  LOKX: "Lokvikas Nagari Sahakari Bank Aurangabad",
  LONX: "Lonavala Sahakari Bank",
  LUCX: "Lucknow Urban Co-operative Bank",
  LULX: "Laxmi Urban Co-operative Bank Latur",
  MABL: "Malleshwaram Co-operative Bank",
  MACX: "Mahabhairab Co-operative Urban Bank",
  MADX: "Madhyanchal Gramin Bank",
  MAGX: "Mewar Aanchalik Gramin Bank",
  MAHB: "Bank of Maharashtra",
  MAHG: "Maharashtra Gramin Bank",
  MAHX: "Shree Mahalaxmi Urban Co-operative Credit Bank",
  MAJX: "Manjeri Co-operative Urban Bank",
  MAKX: "Makarpura Industrial Estate Co-operative Bank",
  MALX: "Malviya Urban Co-operative Bank",
  MAMX: "Mandvi Mercantile Co-operative Bank",
  MANX: "Mansa Nagarik Sahakari Bank",
  MAPX: "Maharana Pratap Co-operative Urban Bank",
  MASX: "Mahesh Urban Co-operative Bank Solapur",
  MAUX: "Mantha Urban Co-operative Bank",
  MAVX: "Shree Mahavir Sahakari Bank",
  MAWX: "Manipur Womens Co-operative Bank",
  MAYX: "Mayani Urban Co-operative Bank",
  MBCX: "Mugberia Central Co-operative Bank",
  MBGX: "Dakshin Bihar Gramin Bank",
  MBLX: "Manmandir Co-operative Bank",
  MCAB: "Meghalaya Co-operative Apex Bank",
  MCAX: "Mahesh Urban Co-operative Bank Ahmedpur",
  MCBL: "Mahanagar Co-operative Bank",
  MCBX: "Modern Co-operative Bank",
  MCCX: "Muktsar Central Co-operative Bank",
  MCDX: "Muzaffarnagar District Co-operative Bank",
  MCLX: "Mahendragarh Central Co-operative Bank",
  MCOX: "Mahesh Urban Co-operative Bank",
  MCSX: "Mattancherry Sarvajanik Co-operative Bank",
  MCUX: "Mahaveer Co-operative Urban Bank",
  MDBK: "Model Co-operative Bank",
  MDCB: "Mumbai District Central Co-operative Bank",
  MDCX: "Madurai District Central Co-operative Bank",
  MDEX: "Model Co-operative Bank",
  MDGX: "Rajasthan Marudhara Gramin Bank",
  MDIX: "Mandya District Co-operative Central Bank",
  MDMX: "Mann Deshi Mahila Sahakari Bank",
  MDPX: "Md Pawar Peoples Co-operative Bank Urun Islampur",
  MEDX: "District Co-operative Central Bank",
  MERX: "Meghalaya Rural Bank",
  MEUX: "Meenachil East Urban Co-operative Bank",
  MFCX: "Muzaffarpur Central Co-operative Bank",
  MFUX: "Mahatma Fule Urban Co-operative Bank,amravati",
  MGBX: "Maharashtra Gramin Bank",
  MGCB: "Mogaveera Co-operative Bank",
  MGCX: "Mansing Co-operative Bank",
  MGDX: "Magadh Central Co-operative Bank",
  MGRB: "Malwa Gramin Bank",
  MGSX: "Mangaldai Nagar Samabai Bank",
  MGUX: "Madgaum Urban Co-operative Bank",
  MHCB: "Mizuho Bank",
  MHCX: "Mahoba Urban Co-operative Bank Mahoba",
  MHEX: "Mahamedha Urban Co-operative Bank ( Under Rbi Direction)",
  MHLX: "Mahila Co-operative Bak",
  MHMX: "Shri Mahalaxmi Co-operative Bank Kolhapur",
  MHNX: "Mahanagar Nagrik Sahakari Bank Maryadit",
  MHSX: "Mahesh Sahakari Bank Pune",
  MHUX: "Mahesh Urbank Co-operative Bank Parli V.",
  MIZX: "Mizoram Co-operative Apex Bank",
  MJCX: "Monghyr Jamui Central Co-operative Bank",
  MKPB: "Malkapur Urban Co-operative Bank",
  MKUX: "Malkapur Urban Co-operative Bank",
  MKYX: "Marketyard Commercial Co-operative Bank",
  MLCG: "Merchants Liberal Co-operative Bank",
  MLDX: "Malda District Central Co-operative Bank",
  MMCX: "Mattancherry Mahajanik Co-operative Urban Bank",
  MMMX: "Mahila Nagrik Sahakari Bank Maryadit Mahasamund",
  MNBX: "Mahila Co-operative Nagarik Bank,bharuch",
  MNCX: "Mansa Central Co-operative Bank",
  MNSX: "Mehsana Nagarik Sahakari Bank",
  MOGX: "Moga Central Co-operative Bank",
  MOUX: "Mohol Urban Co-operative Bank",
  MPCX: "Moirang Primary Co-operative Bank",
  MPDX: "Malappuram District Co-operative Bank",
  MPRX: "Madhya Pradesh Rajya Sahakari Bank Maryadit",
  MRBX: "Manipur Rural Bank",
  MRTX: "Maratha Co-operative Bank",
  MSAX: "Mansarovar Urban Co-operative Bank",
  MSBL: "Malad Sahakari Bank",
  MSBX: "M.s.Co-operative Bank",
  MSCI: "Maharashtra State Co-operative Bank",
  MSCX: "Manipur State Co-operative Bank",
  MSHQ: "Mashreq Bank",
  MSLM: "Muslim Co-operative Bank",
  MSNU: "Mehsana Urban Co-operative Bank",
  MSNX: "Mehsana District Central Co-operative Bank",
  MSOX: "Manorama Co-operative Bank Solapur",
  MSSX: "Merchants Souharda Sahakara Bank Niyamitha",
  MUBL: "Municipal Co-operative Bank",
  MUBX: "Maharaja Co-operative Urban Bank",
  MUCG: "Merchants Urban Co-operative Bank",
  MUCX: "Mapusa Urban Co-operative Bank of Goa",
  MUDX: "Madheshwari Urban Development Co-operative Bank",
  MUNX: "Mahudha Nagarik Sahakari Bank",
  MUPX: "Mehmadabad Urban Peoples Co-operative Bank",
  MURX: "Murshidabad District Central Co-operative Bank",
  MUSX: "Muslim Co-operative Bank",
  MVCB: "Sir M Visvesvaraya Co-operative Bank",
  MVCX: "Mahaveer Co-operative Bank",
  MVIX: "Manvi Pattana Souharda Sahakari Bank Ni",
  MXXX: "Maharashtra Mantralaya & Allied Offices Co-operative Bank",
  MYAX: "Meghalaya Co-operative Apex Bank",
  MYCX: "Mayurbhanj Central Co-operative Bank",
  MYSX: "Mysore Chamarajanagar District Co-operative Bank",
  MZCX: "Mizoram Urban Co-operative Development Bank",
  MZRX: "Mizoram Rural Bank",
  NABX: "Nandani Sahakari Bank",
  NACX: "Nalanda Central Co-operative Bank Nalanda",
  NADX: "Nadapuram Co-operative Bank",
  NAGX: "Nagaland Rural Bank",
  NAIX: "Nainital District Co-operative Bank",
  NALX: "National Urban Co-operative Bank",
  NANX: "Nanded District Central Co-operative Bank",
  NASX: "Nasik District Central Co-operative Bank",
  NATA: "National Australia Bank",
  NAUX: "Nagaur Central Co-operative Bank",
  NAVX: "Navnirman Co-operative Bank",
  NAWX: "Nawanagar Co-operative Bank",
  NBAD: "National Bank of Abu Dhabi PJSC",
  NBBX: "National Co-operative Bank Bangalore",
  NBCX: "Nalbari Urban Co-operative Bank",
  NBMX: "Nagrik Sahakari Bank, Vidisha",
  NBRD: "National Bank for Agriculture and Development",
  NCBL: "National Co-operative Bank",
  NCBX: "Nawanshahr Central Co-operative Bank",
  NCCX: "Nabadwip Co-operative Credit Bank",
  NCOX: "Nagnath Urban Co-operative Bank Hingoli",
  NCUB: "Nilambur Co-operative Urban Bank",
  NCUX: "Nilambur Co-operative Urban Bank",
  NDCB: "Naval Dockyard Co-operative Bank",
  NDCX: "Nilgiris District Central Co-operative Bank",
  NDDX: "Nadia District Central Co-operative Bank",
  NDGX: "Nashik District Girna Sahakari Bank",
  NDIX: "Nashik District Industrial & Mercantile Co-operative Bank",
  NDOX: "Nayagarh District Central Co-operative Bank",
  NESF: "North East Small Finance Bank",
  NEYX: "Neyyattinkara Co-operative Urban Bank",
  NGBX: "Nagar Sahakari Bank Maharajganj",
  NGKX: "Nagrik Sahakari Bank Maryadit Gwalior",
  NGNX: "Nagina Urban Co-operative Bank",
  NGRX: "Nagar Sahakari Bank",
  NGSB: "Nagpur Nagarik Sahakari Bank",
  NGSX: "Nagarik Sahakari Bank Maryadit Durg",
  NGUX: "Nagaur Urban Co-operative Bank",
  NICB: "New India Co-operative Bank",
  NIDX: "Nidhi Co-operative Bank",
  NILX: "Nilkanth Co-operative Bank",
  NIRX: "Nirmal Urban Co-operative Bank Nagpur",
  NIUX: "Nileshwar Co-operative Urban Bank",
  NJBK: "Nav Jeevan Co-operative Bank",
  NJCX: "Nav Jeevan Co-operative Bank",
  NJGX: "Narmada Jhabua Gramin Bank",
  NJMX: "Nashik Zila Mahila Vikas Sahakari Bank",
  NJSX: "Nasik Zila Mahila Sahakari Bank",
  NKGS: "NKGSB Co-operative Bank",
  NLGX: "Nalgonda Dist. Co-operative Central Bank",
  NLUX: "National Urban Co-operative Bank,bahraich",
  NMCB: "Nasik Merchants Co-operative Bank",
  NMCX: "Navi Mumbai Co-operative Bank",
  NMGB: "North Malabar Gramin Bank",
  NNCX: "Naroda Nagrik Co-operative Bank",
  NNSB: "Nutan Nagarik Sahakari Bank",
  NNSX: "Nutan Nagari Sahakari Bank Ichalkaranji",
  NOBX: "Noble Co-operative Bank",
  NOIX: "Noida Commercial Co-operative Bak",
  NOSC: "Bank of Nova Scotia",
  NPCX: "Nadiad Peoples Co-operative Bank",
  NPKX: "Nashik Zilha Sarkari & Parishad Karmachari Sb Nmt",
  NPPX: "Nabagram Peoples' Co-operative Credit Bank",
  NRDX: "Nasik Road Deolali Vyapari Sahakari Bank",
  NRMX: "Northern Railway Multi State Primary Co-operative Bank",
  NSBB: "Nagarik Sahakari Bank Bhiwandi",
  NSBX: "Nagrik Sahakari Bank Lucknow",
  NSCX: "Nagaland State Co-operative Bank",
  NSGX: "Nagar Sahakari Bank Gorakhpur",
  NSIX: "Nagrik Sahakari Bank Indore",
  NSJX: "Nagarik Sahakari Bank Maryadit,jagdalpur",
  NSMX: "Nagarik Samabay Bank",
  NSPB: "NSDL Payments Bank",
  NSPX: "Nishigandha Sahakari Bank",
  NSRX: "Nagrik Sahakari Bank",
  NTBL: "Nainital Bank",
  NUBX: "New Urban Co-operative Bank Rampur",
  NUCB: "Nagar Urban Co-operative Bank",
  NUCX: "Navabharat Co-operative Urban Bank",
  NULX: "National Urban Co-operative Bank, Pratapgarh",
  NVCX: "Navanagara Urban Co-operative Bank",
  NVNM: "Navnirman Co-operative Bank",
  NVSX: "Nagar Vikas Sahakari Bank",
  NWCX: "Nawada Central Co-operative Bank",
  ODCX: "Osmanabad District Central Co-operative Bank",
  ODGB: "Odisha Gramya Bank",
  OIBA: "HSBC Bank Oman S.A.O.G",
  OMCX: "Ojhar Merchant's Co-operative Bank",
  ONSX: "Omkar Nagreeya Sahakari Bank",
  ORBC: "Oriental Bank of Commerce",
  ORCB: "Odisha State Co-operative Bank",
  OSMX: "Osmanabad Janata Sahakari Bank",
  PABX: "Pallavan Grama Bank",
  PACX: "Pali Central Co-operative Bank",
  PADX: "Padra Nagar Nagrik Sahakari Bank",
  PALX: "Pali Urban Co-operative Bank",
  PANX: "Panchmahal District Co-operative Bank",
  PARX: "Parbhani District Central Co-operative Bank",
  PASX: "Paschim Banga Gramin Bank",
  PATX: "Pathanmthitta District Co-operative Bank",
  PAYX: "Payangadi Urban Co-operative Bank",
  PBGX: "Puduvai Bharathiar Grama Bank",
  PCBL: "Patan Co-operative Bank",
  PCBX: "Parshwanath Co-operative Bank",
  PCCB: "Purulia Central Co-operative Bank",
  PCCX: "Panchkula Central Co-operative Bank",
  PCLX: "Pragathi Co-operative Bank, Bangalore",
  PCMX: "Panchsheel Mercantile Co-operative Bank",
  PCOX: "Peoples' Co-operative Bank",
  PCPX: "Pimpri Chinchwad Sahakari Bank Maryadit,pimpri",
  PCSX: "Pondicherry State Co-operative Bank",
  PCTX: "Pune Cantonment Sahakari Bank",
  PCUX: "Pochampally Co-operative Urban Bank",
  PDBX: "Purnea District Central Co-operative Bank",
  PDCX: "Pune District Central Co-operative Bank",
  PDNX: "Pandyan Grama Bank",
  PDSX: "Priyadarshani Nagari Sahakari Bank Jalna.",
  PDUX: "Pandharpur Urban Co-operative Bank",
  PGBX: "Pragathi Krishna Gramin Bank",
  PGCX: "Progressive Co-operative Bank",
  PGRX: "Progressive Urban Co-operative Bank",
  PGTX: "Pragati Co-operative Bank, Thara",
  PHIX: "Panihati Co-operative Bank",
  PITX: "Pithoragarh Zila Sahakari Bank",
  PJSB: "Gopinath Patil Parsik Janata Sahakari Bank",
  PKBX: "Panchkula Urban Co-operative Bank Lmited",
  PKDX: "Palakkad District Co-operative Bank",
  PKGB: "Karnataka Gramin Bank",
  PLOX: "Palamoor Co-operative Urban Bank",
  PLUX: "Palus Sahakari Bank",
  PMCB: "Punjab & Maharashtra Co-operative Bank",
  PMCX: "Pimpalgaon Merchants Co-operative Bank",
  PMEC: "Prime Co-operative Bank",
  PMNX: "Pragati Mahila Nagrik Sahakari Bank Bhilai",
  PNCX: "Panipat Urban Co-operative Bank",
  PNMX: "Pune Merchant's Co-operative Bank",
  PNPX: "Panipat Central Co-operative Bank",
  PNSX: "Poornawadi Nagrik Sahakari Bank",
  PPBX: "Pune People's Co-operative Bank",
  PPCX: "Pachora Peoples Co-operative Bank",
  PRCX: "Proddatur Co-operative Town Bank",
  PREX: "Prerana Co-operative Bank",
  PROX: "Progressive Mercantile Co-operative Bank",
  PRPX: "Paraspar Sahayak Co-operative Bank",
  PRSX: "Pravara Sahakari Bank",
  PRTH: "Prathama Bank",
  PRUX: "Priyadarshani Urban Co-operative Bank",
  PSBL: "Pavana Sahakari Bank",
  PSBX: "Pragati Sahakari Bank",
  PSCX: "Punjab State Co-operative Bank",
  PSIB: "Punjab & Sind Bank",
  PSRX: "Sri Potti Sriramulu Nellore Dccb",
  PSSX: "Prathamik Shikshak Sahakari Bank",
  PTCX: "Patiala Central Co-operative Bank",
  PTNX: "Patan Urban Co-operative Bank Patan",
  PTSX: "Patan Nagarik Sahakari Bank",
  PUBX: "People's Urban Co-operative Bank",
  PUCB: "Pandharpur Urban Co-operative Bank",
  PUCX: "Pusad Urban Co-operative Bank",
  PUDX: "Pudukottai District Central Co-operative Bank",
  PUGX: "Punjab Gramin Bank",
  PUNB: "Punjab National Bank",
  PUNX: "Pune Urban Co-operative Bank",
  PURX: "Purvanchal Gramin Bank",
  PUSD: "Pusad Urban Co-operative Bank",
  PUSX: "Purasawalkam Co-operative Bank",
  PUUX: "Puri Urban Co-operative Bank",
  PVAX: "Pavana Sahakari Bank",
  PVCX: "Purvanchal Co-operative Bank Gazipur",
  PVVX: "Padmashri Dr. Vitthalrao Vikhe Patil Co-operative Bank",
  PWUX: "Parwanoo Urban Co-operative Bank",
  PYCX: "Payyoli Co-operative Urban Bank",
  PYTM: "Paytm Payments Bank",
  QNBA: "Qatar National Bank",
  QNBX: "Qatar National Bank",
  QUCX: "Quilon Co-operative Urban Bank",
  RABO: "Rabobank International",
  RACX: "Rajkot Commercial Co-operative Bank",
  RAEX: "Railway Employees Co-operative Bank",
  RAJX: "Rajsamand Urban Co-operative Bank",
  RAKX: "Rajkot Peoples Co-operative Bank",
  RAMX: "Rajputana Mahila Urban Co-operative Bak",
  RANX: "Rander Peoples Co-operative Bank",
  RATN: "RBL Bank",
  RAUX: "Rajlaxmi Urban Co-operative Bank",
  RBBX: "Rajarambapu Sahakari Bank Peth",
  RBCX: "Reserve Bank Employees Co-operative Bank",
  RBIH: "IDRBT",
  RBIN: "Reserve Bank Of India",
  RBIP: "Reserve Bank of India",
  RBIS: "Reserve Bank of India",
  RCBX: "Raniganj Co-operative Bank",
  RCCX: "Ropar Central Co-operative Bank",
  RCDX: "Ramanathapuram District Central Co-operative Bank",
  RCMX: "Ravi Commercial Urban Co-operative Bank",
  RCUX: "Rajadhani Co-operative Urban Bank",
  RDCB: "Rajnandgaon District Central Co-operative Bank",
  RDCX: "Raigad District Central Co-operative Bank",
  RDNX: "Col Rd Nikam Sainik Sahakari Bank",
  REBX: "Rendal Sahakari Bank Rendal",
  RECX: "Railway Employees Co-operative Banking Society",
  REWX: "Rewari Central Co-operative Bank",
  RGCX: "Ramgarhia Co-operative Bank",
  RGSX: "Rajgurunagar Sahakari Bank",
  RHMX: "Raj Laxmi Mahila Urban Co-operative Bank Jaipur",
  RJCX: "Raiganj Central Co-operative Bank",
  RJJX: "Rajajinagar Co-operative Bank",
  RJNX: "Rajpipla Nagarik Sahakari Bank",
  RJTX: "Shri Rajkot District Co-operative Bank",
  RKCX: "Ranchi Khunti Central Co-operative Bank",
  RLUX: "Ranilaxmibai Urban Co-operative Bank",
  RMGB: "Rajasthan Marudhara Gramin Bank",
  RNBX: "Ranuj Nagrik Sahakari Bank",
  RNDX: "Ratnagiri District Central Co-operative Bank",
  RNGX: "Ranga Reddy Co-operative Urban Bank",
  RNSB: "Rajkot Nagarik Sahakari Bank",
  RNSX: "Rajdhani Nagar Sahakari Bank",
  ROCX: "Rohika Central Co-operative Bank Madhubani",
  ROHX: "Rohtak Central Co-operative Bank",
  RPUX: "Rajapur Urban Co-operative Bank",
  RRBP: "Rajarambapu Sahakari Bank",
  RRSX: "Ramrajya Sahakari Bank",
  RSBL: "Rajgurunagar Sahakari Bank",
  RSBX: "Raigad Sahakari Bank",
  RSCB: "Rajasthan State Co-operative Bank",
  RSSB: "Rajarshi Shahu Sahakari Bank",
  RSSX: "Rajarshi Shahu Sahakari Bank",
  RSUX: "Radhasoami Urban Co-operative Bank",
  RSVX: "Rajarshi Shahu Govt Servants Co-operative Bank Kolh",
  RUCX: "Rajasthan Urban Co-operative Bank",
  RUKX: "Shri Rukmini Sahakari Bank",
  RUMX: "Raipur Urban Mercantile Co-operative Bank",
  RZSX: "Rampur Zila Sahakari Bank",
  SABR: "SBER Bank",
  SABX: "Saraswati Sahakari Bank",
  SACB: "Shri Arihant Co-operative Bank",
  SACX: "Sarvodaya Co-operative Bank Mumbai",
  SADX: "Sabarkantha District Central Co-operative Bank",
  SAGX: "Saurashtra Gramin Bank",
  SAHE: "Sahebrao Deshmukh Co-operative Bank",
  SAHX: "Sadhana Sahakari Bank Pune",
  SAIX: "Sanmitra Sahakari Bank",
  SALX: "Salal Sarvodaya Nagarik Sahakari Bank",
  SAMX: "Samata Sahakari Bank",
  SANT: "Sant Sopankaka Sahakari Bank",
  SANX: "Saibaba Nagari Sahakari Bank",
  SAOX: "Shimoga Arecanut Mandy Merchants Co-operative Bank",
  SAPX: "Shrimant Malojiraje Sahakari Bank",
  SARX: "Sardarganj Mercantile Co-operative Bank",
  SASA: "Sahyadri Sahakari Bank",
  SASX: "S.A.S Nagar Central Co-operative Bank",
  SATX: "Adv. Shamraoji Shinde Satyashodhak Bank",
  SAUX: "Saidapet Co-operative Urban Bank",
  SAVX: "Sardar Vallabhbhai Sahakari Bank",
  SAWX: "Sawai Madhopur Kendriya Sahakari Bank",
  SBBJ: "State Bank of Bikaner and Jaipur",
  SBCR: "Shree Basaveshwar Urban Co-operative Bank",
  SBCX: "Sultan's Bathery Co-operative Urban Bank",
  SBHY: "State Bank of Hyderabad",
  SBIN: "State Bank of India",
  SBKX: "Sri Channabasavaswamy Souhardha Pattana Sahak Bank",
  SBLD: "Sonali Bank",
  SBLS: "Samarth Sahakari Bank",
  SBLX: "Shubhalakshmi Mahila Co-operative Bank",
  SBMX: "Sri Banashankari Mahila Co-operative Bank",
  SBMY: "State Bank of Mysore",
  SBNX: "Shree Bhavnagar Nagrik Sahakari Bank",
  SBPS: "Sri Basaveshwar Pattana Sahakari Bank",
  SBPX: "Sardar Bhiladwala Pardi People's Co-operative Bank",
  SBSX: "Shivajirao Bhosale Sahakari Bank",
  SBTR: "State Bank of Travancore",
  SBUJ: "Shri Bharat Urban Co-operative Bank Jaysingpur",
  SBUX: "Shree Balaji Urban Co-operative Bank",
  SCBL: "Standard Chartered Bank",
  SCBX: "Sirohi Central Co-operative Bank",
  SCCX: "Salem District Central Co-operative Bank",
  SCDX: "South Canara District Central Co-operative Bank",
  SCIX: "Social Co-operative Bank",
  SCNX: "Shri Chhani Nagrik Sahakari Bank",
  SCOB: "Samruddhi Co-operative Bank",
  SCOX: "Shimoga District Co-operative Central Bank",
  SCPX: "Sangrur Central Co-operative Bank",
  SCSX: "Sree Charan Souhardha Co-operative Bank",
  SCUX: "Sudha Co-operative Urban Bank",
  SDBX: "Samata Co-operative Development Bank",
  SDCB: "Surat District Co-operative Bank",
  SDCE: "Satara District Central Co-operative Bank",
  SDCX: "Sindhudurg District Central Co-operative Bank",
  SDHX: "Solapur Siddheshwar Sahakari Bank",
  SDSX: "Satara District Central Co-operative Bank",
  SDTC: "Shri D T Patil Co-operative Bank",
  SDTX: "Sambalpur District Co-operative Central Bank",
  SDUX: "Sri Sudha Co-operative Bank",
  SECB: "Sree Charan Souhardha Co-operative Bank",
  SEMX: "Secunderabad Mercantile Co-operative Urban Bank",
  SENX: "Sehore Nagrik Sahakari Bank Sehore",
  SEUX: "Sevalia Urban Co-operative Bank",
  SEWX: "Shri Mahila Sewa Sahakari Bank",
  SGBA: "Saurashtra Gramin Bank",
  SGCX: "Sangli District Central Co-operative Bank",
  SGDX: "Sundargarh District Central Co-operative Bank",
  SGLX: "Sangli Sahakari Bank",
  SGSX: "Sadguru Nagrik Sahakari Bank Maryadit",
  SGUX: "Sadguru Gahininath Urban Co-operative Bank Akluj",
  SHAX: "Shankar Nagari Sahakari Bank",
  SHBK: "Shinhan Bank",
  SHBX: "Shriram Urban Co-operative Bank",
  SHCX: "Shimla Urban Co-operative Bank",
  SHEX: "Shree Samarth Sahakari Bank Nashik",
  SHGX: "Shri Ganesh Sahakari Bank",
  SHIX: "Shivalik Small Finance Bank",
  SHKX: "Shikshak Sahakari Bank",
  SHMX: "Sanghamitra Co-operative Urban Bank",
  SHNX: "Shri Kanyaka Nagari Sahakari Bank",
  SHOX: "Sihor Mercantile Co-operative Bank",
  SHRX: "Shree Mahesh Co-operative Bank Nashik",
  SHSX: "Sharad Sahakari Bank",
  SHUB: "Shiggaon Urban Co-operative Bank",
  SHUX: "Shushruti Souharda Sahakara Bank Niyamita",
  SIBL: "South Indian Bank",
  SIBX: "Sikar Kendriya Sahakari Bank",
  SICX: "Sirsa Central Co-operative Bank",
  SIDB: "Small Industries Development Bank Of India",
  SIDC: "Sindhudurg District Central Co-operative Bank",
  SIDX: "Siddhi Co-operative Bank",
  SIGX: "Singhbhum District Central Co-operative Bank",
  SIHX: "Sihor Nagarik Sahakari Bank",
  SIKX: "Sikar Urban Co-operative Bank",
  SINX: "Shihori Nagarik Sahakari Bank",
  SIRX: "Sircilla Co-operative Urban Bank",
  SISX: "Sanmati Sahakari Bank",
  SITX: "Sitamarhi Central Co-operative Bank",
  SIWX: "Siwan Central Co-operative Bank",
  SJGX: "Sutlej Gramin Bank",
  SJSB: "Solapur Janata Sahakari Bank",
  SJSX: "Solapur Janata Sahakari Bank",
  SKCX: "Shrikrishna Co-operative Bank",
  SKKX: "Sikkim State Co-operative Bank",
  SKNB: "Shree Kadi Nagarik Sahakari Bank",
  SKNX: "Sankheda Nagarik Sahakari Bank",
  SKSB: "Shikshak Sahakari Bank",
  SKUX: "S S L S A Kurundwad Urban Bank",
  SLAX: "Subhadra Local Area Bank",
  SLCX: "Shree Laxmi Co-operative Bank",
  SMBC: "Sumitomo Mitsui Banking Corporation",
  SMBX: "Sampada Sahakari Bank",
  SMCB: "Shivalik Small Finance Bank",
  SMCX: "Sangamner Merchants Co-operative Bank",
  SMEX: "Sumerpur Mercantile Urban Co-operative Bank",
  SMMX: "Shree Mahalaxmi Mercantile Co-operative Bank",
  SMNB: "Smriti Nagrik Sahakari Bank",
  SMNX: "Shree Mahuva Nagrik Sahakari Bank",
  SMPX: "Shankarrao Mohite Patil Sahakri Bank",
  SMSX: "Soubhagya Mahila Souhardha Sahakari Bank",
  SMTX: "Sanmitra Urban Co-operative Bank",
  SMUX: "Shri Mahavir Urban Co-operative Bank",
  SMVC: "Sir M Visvesvaraya Co-operative Bank",
  SMWX: "Sree Subramanyeswara Co-operative Bank",
  SNAX: "Saraspur Nagarik Co-operative Bank",
  SNBK: "Saraspur Nagrik Co-operative Bank",
  SNBX: "Sarvodaya Nagrik Sahakari Bank",
  SNCX: "Sonepat Urban Co-operative Bank",
  SNDX: "Sind Co-operative Urban Bank",
  SNGX: "Sarangpur Co-operative Bank",
  SNKX: "Shramik Nagrik Sahakari Bank",
  SNLX: "Sarjeraodada Naik Shirala Sahakari Bank",
  SNPX: "Sonepat Central Co-operative Bank",
  SNSV: "Sarakari Naukarara Sahakari Bank Niyamt Vijayapura",
  SNSX: "Smriti Nagrik Sahakari Bank",
  SOBX: "Secunderabad Co-operative Urban Bank",
  SOGE: "Societe Generale",
  SOLX: "Solapur District Central Co-operative Bank",
  SONX: "Sonbhadra Nagar Sahakari Bank",
  SPBX: "Saptagiri Grameena Bank",
  SPCB: "Surat People's Co-operative Bank",
  SPCX: "Shirpur Peoples Co-operative Bank",
  SPNX: "Shree Panchganga Nagari Sahakari Bank",
  SPSX: "Sandur Pattana Souharda Sahakari Bank Niyamitha",
  SPTX: "Shripatraodada Sahakari Bank",
  SRCB: "Saraswat Co-operative Bank",
  SRCX: "Shree Bharat Co-operative Bank",
  SREX: "Shree Co-operative Bank",
  SRGX: "Sree Narayana Guru Co-operative Bank",
  SRHX: "Shree Dharati Co-operative Bank",
  SRSX: "Sharad Nagari Sahakari Bank",
  SSBL: "Satara Shakari Bank",
  SSBX: "Shivdaulat Sahakari Bank",
  SSDX: "Suco Souharda Sahakari Bank",
  SSHX: "Shree Sharada Sahakari Bank",
  SSKX: "Sadhana Sahakari Bank",
  SSLX: "Solapur Social Urban Co-operative Bank",
  SSNX: "Sreenidhi Souharda Sahakari Bank Niyamitha",
  SSOX: "Samarth Sahakari Bank",
  SSSX: "Sant Sopankaka Sahakari Bank",
  SSWX: "Sundarlal Sawaji Urban Co-operative Bank",
  STBP: "State Bank of Patiala",
  STCB: "SBM Bank",
  STCX: "State Transport Co-operative Bank",
  STDX: "Samastipur District Central Co-operative Bank",
  STRX: "Sterling Urban Co-operative Bank",
  SUBX: "Prathama UP Gramin Bank",
  SUCX: "Sangli Urban Co-operative Bank",
  SUDX: "Surendranagar District Co-operative Bank",
  SULX: "Sulaimani Co-operative Bank",
  SUMX: "Surat Mercantile Co-operative Bank",
  SUNB: "Surat National Co-operative Bank",
  SURX: "Siddheshwar Urban Co-operative Bank Maryadit Sillod",
  SURY: "Suryoday Small Finance Bank",
  SUSB: "Suco Souharda Sahakari Bank",
  SUSX: "Sirsi Urban Sahakari Bank",
  SUTB: "Sutex Co-operative Bank",
  SUVX: "Suvarnayug Sahakari Bank",
  SVAX: "Sri Vasavamba Co-operative Bank",
  SVBL: "Seva Vikas Co-operative Bank",
  SVCB: "SVC Co-operative Bank",
  SVCX: "Sarvodaya Commerical Co-operative Bank",
  SVGX: "Sivagangai District Central Co-operative Bank",
  SVMC: "Shri Vijay Mahantesh Co-operative Bank",
  SVNX: "Shivaji Nagari Sahakari Bank",
  SVOX: "Sarvodaya Sahakari Bank",
  SVRX: "Shree Vardhaman Sahakari Bank",
  SVSH: "Shree Veershaiv Co-operative Bank",
  SVSX: "Shri Vinayak Sahakari Bank",
  SWMX: "Sawai Madhopur Urban Co-operative Bank",
  SWSX: "Shree Warana Sahakari Bank",
  SXXX: "Sri Rama Co-operative Bank",
  SYNB: "Syndicate Bank",
  TACX: "Adinath Co-operative Bank",
  TADX: "Anantapur District Co-operative Central Bank",
  TAMX: "Anand Mercantile Co-operative Bank",
  TAPX: "Tapindu Urban Co-operative Bank",
  TASX: "Annasaheb Savant Co-operative Urban Bank Mahad",
  TAUB: "Ankola Urban Co-operative Bank",
  TBCX: "Bharath Co-operative Bank",
  TBDX: "Buldana District Central Co-operative Bank",
  TBHX: "Bhadran People's Co-operative Bank",
  TBMC: "Banaskantha Mercantile Co-operative Bank",
  TBMX: "Bapunagar Mahila Co-operative Bank",
  TBNS: "Becharaji Nagarik Sahakari Bank",
  TBPX: "Botad Peoples Co-operative Bank",
  TBSB: "Thane Bharat Sahakari Bank",
  TBSX: "Bihar State Co-operative Bank",
  TBTX: "Baroda Traders Co-operative Bank",
  TBUX: "Balusseri Co-operative Urban Bank",
  TCBX: "Co-operative Bank of Rajkot",
  TCCX: "Commercial Co-operative Bank",
  TCHX: "Town Co-operative Bank Hoskote",
  TCPX: "Tamilnadu Circle Postal Co-operative Bank",
  TCUB: "Trivandrum Co-operative Urban Bank",
  TCUX: "Tiruvallur Co-operative Urban Bank",
  TDBX: "District Co-operative Central Bank",
  TDCB: "Thane District Central Co-operative Bank",
  TDCX: "Thrissur District Co-operative Bank",
  TDIX: "District Co-operative Central Bank Kakinada",
  TDMX: "Dhanera Mercantile Co-operative Bank",
  TDPX: "Thodupuzha Urban Co-operative Bank",
  TDSX: "Nizamabad District Co-operative Central Bank",
  TECX: "Eenadu Co-operative Urban Bank",
  TEHX: "Tehri Garhwal Zila Sahakari Bank",
  TEMX: "Textile Manufacturers Co-operative Bank",
  TESX: "Textile Co-operative Bank of Surat",
  TETX: "Textile Traders Co-operative Bank",
  TFCX: "Fatehabad Central Co-operative Bank",
  TGBX: "Tripura Gramin Bank",
  TGCG: "Gokak Urban Co-operative Credit Bank",
  TGCX: "Tamluk-ghatal Central Co-operative Bank",
  TGDX: "Gondia District Central Co-operative Bank Gondia",
  TGMB: "Tumkur Grain Merchant's Co-operative Bank",
  TGNX: "Gandhinagar Nagrik Co-operative Bank",
  TGUX: "Gandhinagar Urban Co-operative Bank",
  THCX: "Hooghly Co-operative Credit Bank",
  THMX: "Halol Mercantile Co-operative Bank",
  THOX: "Thoothukudi District Central Co-operative Bank",
  THRS: "Thrissur District Co-operative Bank",
  THRX: "Raichur District Central Co-operative Bank",
  THWX: "Howrah District Central Co-operative Bank",
  TIDX: "Thiruvannamalai District Central Co-operative Bank",
  TIRX: "Tirunelveli District Central Co-operative Bank",
  TJAX: "Jaisalmer Central Co-operative Bank",
  TJBX: "Janata Co-operative Bank Sadalga",
  TJCX: "Thanjavur Central Co-operative Bank",
  TJDX: "Jalgaon District Central Co-operative Bank Jalgaon",
  TJMX: "Jaynagar Mozilpur Peoples Co-operative Bank",
  TJNX: "Jamnagar Mahila Sahakari Bank",
  TJSB: "TJSB Sahakari Bank",
  TKAX: "Karjan Nagrik Sahakari Bank",
  TKCX: "Kakatiya Co-operative Urban Bank",
  TKDX: "Kannur District Co-operative Bank",
  TKTX: "Kottakkal Co-operative Urban Bank",
  TKUX: "Karan Urban Co-operative Bank",
  TLPX: "Lunawada Peoples Co-operative Bank",
  TMAX: "Madanapalle Co-operative Town Bank",
  TMBL: "Tamilnad Mercantile Bank",
  TMBX: "Mangalore Catholic Co-operative Bank",
  TMCX: "Motihari Central Co-operative Bank",
  TMNX: "Mahanagar Co-operative Urban Bank",
  TMPX: "Midnapore Peoples Co-operative Bank",
  TMSB: "The Malad Sahakari Bank",
  TMSC: "Mysore Silk Cloth Merchants Co-operative Bank",
  TMSX: "Modasa Nagarik Sahakari Bank",
  TMTX: "Mangalore Co-operative Town Bank",
  TMUX: "Mahila Urban Co-operative Bank",
  TNBX: "Meghraj Nagarik Sahakari Bank",
  TNCB: "Nawanagar Co-operative Bank",
  TNCX: "National Central Co-operative Bank Bettiah",
  TNDC: "Thiruvananthapuram District Co-operative Bank",
  TNEX: "Nehrunagar Co-operative Bank",
  TNHX: "Nakodar Hindu Urban Co-operative Bank",
  TNIX: "Tamilnadu Industrial Co-operative Bank",
  TNKX: "Neela Krishna Co-operative Urban Bank",
  TNMX: "Nanded Merchants Co-operative Bank Nanded",
  TNSC: "Tamilnadu State Apex Co-operative Bank",
  TNUX: "Nandura Urban Co-operative Bank Nandura",
  TOCX: "Ottapalam Co-operative Urban Bank",
  TPCX: "Patliputra Central Co-operative Bank",
  TPDX: "Prakasam District Co-operative Central Bank",
  TPSC: "Punjab State Co-operative Bank",
  TPSX: "Tripura State Co-operative Bank",
  TPUX: "Urban Co-operative Bank No 1758 Perinthalmanna",
  TRAX: "Transport Co-operative Bank",
  TRDX: "Tiruchirapalli Dist. Cent Co-operative Bank",
  TSAB: "Telangana State Co-operative Apex Bank",
  TSAX: "Satana Merchants Co-operative Bank, Satana",
  TSBX: "Sasaram Bhabhua Central Co-operative Bank",
  TSCX: "Santragachi Co-operative Bank",
  TSDX: "District Co-operative Central Bank Srikakul",
  TSIX: "Shillong Co-operative Urban Bank",
  TSKX: "Aska Co-operative Central Bank",
  TSMX: "Sardargunj Mercantile Co-operative Bank Patan",
  TSNX: "Shoranur Co-operative Urban Bank",
  TSPX: "Sathamba Peoples Co-operative Bank",
  TSSB: "Satara Sahakari Bank",
  TSSX: "S.S.K Co-operative Bank",
  TSUX: "Saurashtra Co-operative Bank",
  TTBX: "Taliparamba Co-operative Urban Bank",
  TTCB: "Textile Traders Co-operative Bank",
  TTCX: "Tarn Taran Central Co-operative Bank",
  TTGX: "Tasgaon Urban Co-operative Banktasgaon",
  TTLX: "Textile Co-operative Bank",
  TTUX: "Tirur Urban Co-operative Bank",
  TUBX: "Trichur Urban Co-operative Bank",
  TUCL: "The Union Co-operative Bank Mahinagar",
  TUCX: "Tirupati Urban Co-operative Bank",
  TUDX: "Urban Co-operative Bank Dharangaon",
  TUMX: "Udaipur Mahila Urban Co-operative Bank",
  TUNX: "Union Co-operative Bank",
  TUOX: "Urban Co-operative Bank Saharanpur",
  TUPX: "Uttarsanda Peoples Co-operative Bank Uttarsanda",
  TURX: "Tura Urban Co-operative Bank",
  TVBX: "Vellala Co-operative Bank",
  TVDX: "District Co-operative Central Bank Vizianagaram",
  TVPX: "Veraval Peoples Co-operative Bank",
  TVUX: "Vita Urban Co-operative Bank",
  TYCX: "Shree Thyagaraja Co-operative Bank",
  UBBX: "Urban Co-operative Bank Basti",
  UBGX: "Uttar Bihar Gramin Bank",
  UBIN: "Union Bank of India",
  UCBA: "UCO Bank",
  UCBS: "Utkal Co-operative Banking Society",
  UCBX: "Urban Co-operative Bank Bareilly",
  UCCX: "Udaipur Central Co-operative Bank",
  UCDX: "Urban Co-operative Bank Dehradun",
  UCUX: "Universal Co-operative Urban Bank",
  UGBX: "Utkal Grameen Bank",
  UICX: "United India Co-operative Bank",
  UJSX: "Unjha Nagarik Sahakari Bank",
  UJVN: "Ujjivan Small Finance Bank",
  UKGX: "Uttarbanga Kshetriya Gramin Bank",
  UMAX: "Uma Co-operative Bank",
  UMCX: "Umreth Urban Co-operative Bank",
  UMSX: "Udaipur Mahila Samridhi Urban Co-operative Bank",
  UMUX: "Umiya Urban Co-operative Bank",
  UNAX: "Una Peoples Co-operative Bank",
  UNIX: "United Co-operative Bank",
  UNMX: "United Mercantile Co-operative Bank",
  UNSX: "Unava Nagrik Sahakari Bank",
  UOVB: "United Overseas Bank",
  UPCB: "Uttar Pradesh Co-operative Bank",
  UPCX: "Uttar Pradesh Co-operative Bank",
  UPNX: "United Puri Nimapara Central Bank",
  URBN: "Urban Co-operative Bank Perinthalmanna",
  URCX: "Urban Co-operative Bank Siddharthnagar",
  URDX: "Urban Co-operative Bank Budaun",
  URMX: "Urban Co-operative Bank Mainpuri",
  UROX: "Urban Co-operative Bank Rourkela",
  USFB: "Ujjivan Small Finance Bank",
  USNX: "Udham Singh Nagar District Co-operative Bank",
  UTBI: "United Bank of India",
  UTBX: "Uttarpara Co-operative Bank",
  UTCX: "Uttrakhand Co-operative Bank",
  UTGX: "Uttarakhand Gramin Bank",
  UTIB: "Axis Bank",
  UTKS: "Utkarsh Small Finance Bank",
  UTKX: "Uttarakhand State Co-operative Bank",
  UTZX: "Uttarkashi Zila Sahakari Bank",
  UUCB: "Udaipur Urban Co-operative Bank",
  UUCX: "Udaipur Urban Co-operative Bank",
  UXXX: "Udupi Co-operative Town Bank",
  VADX: "Valsad District Central Co-operative Bank",
  VAIX: "Vaishya Sahakari Bank Mumbai",
  VANX: "Vaishya Nagari Sahakari Bank",
  VARA: "Varachha Co-operative Bank",
  VASJ: "Vasai Janata Sahakari Bank",
  VASX: "Vyaparik Audhyogik Sahakari Bank",
  VAUX: "Valmiki Urban Co-operative Bank",
  VCAX: "Vaish Co-operative Adarsh Bank",
  VCBX: "Vishwas Co-operative Bank",
  VCCX: "Shri Veershaiv Co-operative Bank",
  VCNB: "Vaish Co-operative New Bank",
  VCOB: "The Vijay Co-operative Bank",
  VCOX: "Vita Merchants Co-operative Bank",
  VDCX: "Villupuram District Central Co-operative Bank",
  VDYX: "Vidyanand Co-operative Bank",
  VEDX: "Vellore District Central Co-operative Bank",
  VERX: "Veraval Mercantile Co-operative Bank",
  VGBX: "Jharkhand Rajya Gramin Bank",
  VHDX: "Vaishali District Central Co-operative Bank",
  VICX: "Vijay Commercial Co-operative Bank",
  VIDX: "Vidyasagar Central Co-operative Bank",
  VIJB: "Vijaya Bank",
  VIJX: "Vijay Co-operative Bank",
  VIKX: "Vikramaditya Nagrik Sahakari Bank",
  VIMX: "Viramgam Mercantile Co-operative Bank",
  VIRX: "Virudhunagar District Central Co-operative Bank",
  VISX: "Visakhapatnam Co-operative Bank",
  VJSX: "Vasai Janata Sahakari Bank",
  VKCX: "Vima Kamgar Co-operative Bank",
  VKSX: "Vikas Sahakari Bank Solapur",
  VMCX: "Vardhaman (mahila) Co-operative Urban Bank",
  VMMX: "Vaijapur Merchants Co-operative Bank",
  VMUX: "Vidarbha Merchants Urban Co-operative Bank",
  VNSX: "Vivekanand Nagrik Sahakari Bank Mydt",
  VRDX: "Vardhaman Co-operative Bank",
  VSBL: "Vishweshwar Sahakari Bank",
  VSBX: "Vidya Sahakari Bank",
  VSCX: "Vikas Souharda Co-operative Bank",
  VSSX: "Veerashaiva Sahakari Bank",
  VSVX: "Vaishali Shahari Vikas Co-operative Bank",
  VUCX: "Vaidyanath Urban Co-operative Bank",
  VVCX: "Vallabh Vidyanagar Commercial Bank",
  VVSB: "Vasai Vikas Sahakari Bank",
  VYAX: "Vyavsayak Sahakari Bank",
  VYPX: "Vyapari Sahakari Bank Maryadit Solapur",
  VYSA: "ING Vysya Bank",
  WACX: "Waghodia Urban Co-operative Bank",
  WAIX: "Wai Urban Co-operative Bank",
  WARX: "Warangal District Co-operative Central Bank",
  WAUX: "Wardhaman Urban Co-operative Bank Nagpur",
  WBSC: "West Bengal State Co-operative Bank",
  WCBX: "Women's Co-operative Bank",
  WDCX: "Wayanad District Co-operative Bank",
  WKGX: "Vidharbha Kokan Gramin Bank",
  WNBX: "Wana Nagirik Sahakari Bank",
  WPAC: "Westpac Banking Corporation",
  WRCX: "Warangal Urban Co-operative Bank",
  WUCX: "Washim Urban Co-operative Bank.",
  WZUX: "Wardha Zilla Parishad Emp Urban Co-operative Bank",
  XJKG: "J&K Grameen Bank",
  YADX: "Yavatmal District Central Co-operative Bank",
  YAVX: "Yavatmal Urban Co-operative Bank",
  YCBX: "Yashwant Co-operative Bank",
  YDCX: "Youth Development Co-operative Bank",
  YESB: "Yes Bank",
  YLNX: "Yadagiri Lakshmi Narsimha Swamy Co-operative Urban Bank",
  YMSX: "Yavatmal Mahila Sahakari Bank",
  YNCX: "Yamuna Nagar Central Co-operative Bank",
  YNSX: "Yeshwant Nagari Sahakari Bank",
  ZBBX: "Zila Sahakari Bank Bulandshahar",
  ZBSX: "Zila Sahakari Bank Mathura",
  ZCBL: "Zoroastrian Co-operative Bank",
  ZIBX: "Zila Sahakari Bank Bareilly",
  ZILX: "Zila Sahakari Bank Unnao",
  ZIMX: "Zila Sahakari Bank Mirzapur",
  ZISX: "Zila Sahakari Bank Lucknow",
  ZLLX: "Zila Sahakari Bank Lalitpur",
  ZMMX: "Zila Sahakari Bank Moradabad",
  ZRNB: "Rani Channamma Mahila Sahakari Bank",
  ZSAX: "Zila Sahakari Bank Mau",
  ZSBG: "Zila Sahakari Bank Gorakhpur",
  ZSBL: "Zila Sahakari Bank Ghaziabad",
  ZSBX: "Zila Sahakari Bank Bijnor",
  ZSGX: "Zila Sahakari Bank Garhwal Kotdwar",
  ZSHX: "Zila Sahakari Bank Haridwar",
  ZSJX: "Zila Sahakari Bank Jhansi",
  ZSKX: "Zila Sahakari Bank Kanpur",
  ZSLX: "Zila Sahakari Bank Lakhimpur Kheri",
  ZSMX: "Zila Sahakari Bank Meerut",
};
export default CompanyInfoOperator;
