import React, { useState } from "react";
import "./DistBusinessDetails.scss";
import { GESRecords } from "../../Utilsx/Encrypt";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { maskAadhaar, validateGstNumber } from "../utils/validations";

import { validateEmail, validatePanNo } from "../utils/validations";
import { Spinner } from "react-bootstrap";
import TextInputWrapper from "../components/TextInputWrapper";
import useStore from "../../store";

const DistBusinessDetails = () => {
  const location = useLocation();
  const user = useStore((state) => state.user);
  const popNavStack = useStore((state) => state.popNavStack);
  console.log("usr", user.NavStack);

  const setUser = useStore((state) => state.setUser);
  const [locationState, setlocationState] = useState(() => {
    if (user && Object.keys(user).length > 0) {
      return user;
    }

    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });

  console.log("state obj", locationState);
  const [agencyName, setAgencyName] = useState(locationState.agencyName || "");
  const [businessEmail, setBusinessEmail] = useState(locationState.email || "");
  const [gstNumber, setGstNumber] = useState("");
  const [businessPan, setBusinessPan] = useState("");
  const [udyamAadhar, setUdyamAadhar] = useState("");
  const [institutionType, setInstitutionType] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [errors, setErrors] = useState({});
  let apiEN = process.env.REACT_APP_API_apiEN;
  const navigate = useNavigate();

  console.log(locationState.role);
  console.log("address:", address);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate the form fields
    if (!agencyName) newErrors.agencyName = "Agency Name is required";
    if (!businessEmail) newErrors.businessEmail = "Business Email is required";
    // if (!businessPan) newErrors.businessPan = "Business PAN is required";
    // if (!institutionType) newErrors.institutionType = "Institution Type is required";
    if (!address) newErrors.address = "Address is required";
    if (!state) newErrors.state = "State is required";
    if (!city) newErrors.city = "City is required";
    if (!pincode) newErrors.pincode = "Pincode is required";

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }
    function extractPANFromGST(gstNumber) {
      // Check if the GST number is in the correct format
      const gstRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

      if (!gstRegex.test(gstNumber)) {
        return "Invalid GST Number format";
      }

      // Extract the PAN number (characters from index 2 to 12)
      const panNumber = gstNumber.slice(2, 12);

      return panNumber;
    }

    const submitData = {
      mobile: locationState.MobileNumber,
      BusinessName: agencyName,
      email: businessEmail,
      GSTNumber: gstNumber,
      BusinessPAN: extractPANFromGST(gstNumber),
      Aadhaar: maskAadhaar(udyamAadhar.replace(/-/g, "")),
      InstitutionType: institutionType,
      business_Address: address,
      business_State: state,
      business_District: city,
      business_Pincode: pincode,
    };

    const EncrytedSubmitData = GESRecords(submitData, apiEN);
    console.log("submitData: ", submitData);
    try {
      setisLoading(true);

      if (!validateGstNumber(gstNumber)) {
        alert("Please enter a valid GST number in the format 22AAAAA0000A1Z5");
        return;
      }

      if (!validateEmail(businessEmail)) {
        alert("Please enter a valid email address");
        return;
      }
      // alert(locationState.role);
      const route =
        locationState.role === "Dist"
          ? "/UpdateMerchantBuisnessDetails"
          : "/UpdateDealerBuisnessDetails";

      console.log(`${process.env.REACT_APP_API_LINK}${route}`);
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}${route}`,
        EncrytedSubmitData
      );

      if (response.data.duplicate) {
        alert(response.data.message);
      } else {
        const updatedState = {
          agencyName,
          address,
          state,
          city,
          pincode,
          email: businessEmail,
          ...locationState,
        };

        setUser(updatedState);

        const nextNav = user.NavStack[0];

        if (nextNav) {
          popNavStack();
          navigate(nextNav);
        } else {
          navigate("/DistUploadDocuments", {
            state: updatedState,
          });
        }
      }
    } catch (error) {
      console.log(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="Dist-business-company-info">
      <div className="Dist-business-company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p className="paragraph-el">2 / 6</p>
        <h3>Business Information</h3>
        <h4>
          Welcome! Let's start by getting some details about your business.
        </h4>
        <h5>
          This information will help us understand your operations better and
          ensure that we provide the best services tailored to your needs.
        </h5>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <div className="Dist-form-row">
            <label className="label-font" style={{}}>
              Agency Name*
            </label>
            <TextInputWrapper
              type="text"
              value={agencyName}
              onChange={(e) => setAgencyName(e)}
              placeholder="Enter Agency Name"
              className="placeholder-font"
              required
            />
            {errors.agencyName && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.agencyName}
              </span>
            )}
          </div>
          <div className="Dist-form-row">
            <label className="label-font">Business Email ID*</label>
            <TextInputWrapper
              type="email"
              value={businessEmail}
              onChange={(e) => {
                if (!validateEmail(e)) {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      businessEmail: "Please enter a valid email ID",
                    };
                  });
                  return setBusinessEmail(e);
                } else {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      businessEmail: null,
                    };
                  });
                  return setBusinessEmail(e);
                }
              }}
              placeholder="Enter Business Email ID"
              className="placeholder-font"
              required
            />
            {errors.businessEmail && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.businessEmail}
              </span>
            )}
          </div>
          <div className="Dist-form-row">
            <label className="label-font">GST Number*</label>
            <TextInputWrapper
              type="text"
              value={gstNumber}
              onChange={(e) => {
                if (!validateGstNumber(e.toLocaleUpperCase())) {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      gstNumber:
                        "Please enter a valid GST number in the format 22AAAAA0000A1Z5",
                    };
                  });
                  return setGstNumber(e.toLocaleUpperCase());
                } else {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      gstNumber: null,
                    };
                  });
                  return setGstNumber(e.toLocaleUpperCase());
                }
              }}
              placeholder="Enter GST Number"
              className="placeholder-font"
              required
            />
            {errors.gstNumber && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.gstNumber}
              </span>
            )}
            {/* <label className="label-font">Business PAN</label>
            <TextInputWrapper
              type="text"
              value={businessPan}
              onChange={(e) =>
                setBusinessPan(e.toLocaleUpperCase())
              }
              placeholder="Enter Business PAN"
              className="placeholder-font"
              required
            />
            {errors.businessPan && (
              <span className="error" style={{ fontSize: 16 }}>{errors.businessPan}</span>
            )} */}
          </div>

          <div className="Dist-form-row">
            <label className="label-font">Address*</label>
            <TextInputWrapper
              type="text"
              value={address}
              onChange={(e) => setAddress(e)}
              placeholder="Enter Address"
              className="placeholder-font"
              required
            />
            {errors.address && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.address}
              </span>
            )}
          </div>
          <div className="Dist-form-row">
            <label className="label-font">State*</label>
            <select
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Select State"
              className="placeholder-font"
              required
            >
              <option value="" disabled>
                Select State
              </option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadra and Nagar Haveli and Daman and Diu">
                Dadra and Nagar Haveli and Daman and Diu
              </option>
              <option value="Delhi">Delhi</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">Puducherry</option>
            </select>
            {errors.state && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.state}
              </span>
            )}
          </div>

          <div className="Dist-form-row">
            <label className="label-font">City*</label>
            <TextInputWrapper
              type="text"
              value={city}
              onChange={(e) => setCity(e)}
              placeholder="Select City"
              className="placeholder-font"
              required
            />
            {errors.city && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.city}
              </span>
            )}
          </div>

          <div className="Dist-form-row">
            <label className="label-font">Pincode*</label>
            <TextInputWrapper
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e)}
              placeholder="Enter Pincode"
              className="placeholder-font"
              required
            />
            {errors.pincode && (
              <span className="error" style={{ fontSize: 16 }}>
                {errors.pincode}
              </span>
            )}
          </div>
          <button className="Dist-business-button" type="submit">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DistBusinessDetails;
