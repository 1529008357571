import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  align-self: stretch;
  background-color: #3f0261;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100vw; // Ensure the container covers the full width of the viewport
  padding: 2.018rem 1.25rem;
  gap: 0.243rem;
  text-align: center;
  font-size: 1.338rem;
  color: #ffffff;
  font-family: var(--heading-h6);
  overflow-x: hidden;

  @media (max-width: 825px) {
    flex-wrap: wrap;
  }
`;

const FooterText = styled.b`
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: ${(props) => props.minWidth || "auto"};

  @media (max-width: 450px) {
    font-size: 1.063rem;
    line-height: 1.625rem;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText minWidth="7.938rem">Copyright ©</FooterText>
      <FooterText minWidth="3.375rem">2024</FooterText>
      <FooterText minWidth="4.875rem">Greattr</FooterText>
      <FooterText>All Rights Reserved</FooterText>
    </FooterContainer>
  );
};

export default Footer;
