import React from "react";
import styled from "styled-components";
import Card from "./Card";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import ItemCard from "./ItemCard";

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.287rem;
  max-width: 100%;
  text-align: left;
  font-size: 2.319rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
    gap: 1.125rem;
  }

  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.318rem;
  min-width: 43.938rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    min-width: 100%;
  }
`;

const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.4rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 825px) {
    gap: 1.188rem;
  }

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

const FeaturesContainer = ({ className = "" }) => {
  return (
    <Container className={className}>
      <LeftColumn>
        <Row>
          <ItemCard
            title="Increased Sales"
            description="Offer BNPL options to attract more customers."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
          <ItemCard
            title="Customer Support"
            description="Guide customers seamlessly through our loans."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
        </Row>
        <Row>
          <ItemCard
            title="Enhanced Loyalty"
            description="Build customer loyalty with flexible financial solutions."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
          <ItemCard
            title="Business Growth"
            description="Grow your business with flexible payment options."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
        </Row>
      </LeftColumn>
      <Card
        forConsumers="For Merchants"
        enjoyFlexiblePaymentOptio="Increase sales and customer satisfaction"
        suitYourBudget="with BNPL options."
        app="/app-1@2x.png"
        propPadding="4.568rem 1.25rem 0.031rem 1.375rem"
      />
    </Container>
  );
};

FeaturesContainer.propTypes = {
  className: PropTypes.string,
};

export default FeaturesContainer;
