import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Marquee from "react-marquee-slider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Section = styled.section`
  background: #fff;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: content-box;
  max-width: 100%;
  text-align: center;
  font-size: 4.569rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
  }

  @media (max-width: 1250px) {
  }

  @media (max-width: 450px) {
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2.287rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1.125rem;
  }
`;

const ContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.143rem;
  max-width: 100%;
`;

const Title = styled(motion.div)`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 110%;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 9rem;

  @media (max-width: 825px) {
    font-size: 6.625rem;
    line-height: 6rem;
  }

  @media (max-width: 450px) {
    font-size: 4.75rem;
    line-height: 4.7rem;
  }
`;

const Subtitle = styled(motion.div)`
  align-self: stretch;
  position: relative;
  font-size: 2.431rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  margin-bottom: 50;

  @media (max-width: 450px) {
    font-size: 1.125rem;
    line-height: 2.063rem;
  }
`;

const MarqueeImage = styled.img`
  height: 30rem;
  margin: 0 20px;
`;

const titleVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const DreamsWithin = ({ className }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <Section className={className}>
      <Container>
        <ContentWrapper>
          <Content>
            <Title
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            >
              Your Dreams Within Reach
            </Title>
            <Subtitle
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            >
              Small Loans for Big Aspirations: Achieve More with
              <br />
              Greattr’s Flexible and Accessible Loan Options
            </Subtitle>
            <Marquee speed={1} gradient={true} autofill={true}>
              <MarqueeImage src="/Agro_Drone.png" alt="Agro_Drone" />
              <MarqueeImage src="/eRickshaw.png" alt="Bajaj Housing Finance" />
              <MarqueeImage src="/LPG_New.png" alt="Aditya Birla Capital" />
              <MarqueeImage src="/LPG_Refill.png" alt="Mufin Green Finance" />
              <MarqueeImage src="/Rooftop_Solar.png" alt="Hindon Mercantile" />
              <MarqueeImage src="/SHGs.png" alt="Mobileware" />
              <MarqueeImage src="/Solar_Charkha.png" alt="Blostem" />
              <MarqueeImage src="/Street_Vendors.png" alt="Auro Fintech" />
            </Marquee>
          </Content>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

DreamsWithin.propTypes = {
  className: PropTypes.string,
};

export default DreamsWithin;
