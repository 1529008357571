import React, { useState, useEffect } from "react";
import "./AccountCreatedSuccessfully.scss";
import AccountCreatedLogo from "./assets/AccountCreatedLogo.png";

const AccountCreatedSuccessfully = () => {
  return (
    <div className="AccountCreatedSuccessfully">
      <div
        className="AccountCreatedSuccessfully-container"
        style={{
          display: "flex",
          maxWidth: "none",
        }}
      >
        <div
          style={{
            padding: "5%",
          }}
        >
          <img
            // className="moblogo"
            style={{
              width: "20%",
            }}
            src={AccountCreatedLogo}
            alt=""
          />
        </div>
        <h3 style={{}}>Account created successfully!</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <p style={{
                        width: '65%',
                    }}>Welcome to Greattr! Your account is now active. <br /> Login here to get started: <a href="https://greattr.com/OperatorLogin" style="text-decoration: none;">

                        </a></p> */}
          <p style={{ minWidth: "65%", maxWidth: 500 }}>
            Welcome to Greattr! Your account is now active. Click the button
            below to login. <br />
            <a
              href="https://greattr.com/OperatorLogin"
              style={{ textDecoration: "none" }}
            >
              <button
                style={{
                  marginTop: "10%",
                  backgroundColor: "#573A87",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Get Started
              </button>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountCreatedSuccessfully;
