import React, { useState, useEffect } from "react";

const StepIndicator = ({ steps, onStepClick }) => {
  const [selectedStep, setSelectedStep] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Add window resize listener
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleStepClick = (index, status) => {
    if (status !== "Completed") {
      setSelectedStep(index);
      onStepClick(index);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: isMobile ? "90%" : "700px",
        minWidth: isMobile ? "0" : "700px",
      }}
    >
      {/* Status Indicators */}
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "center",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
          gap: isMobile ? "8px" : "20px", // Adjusted gap for better spacing
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: isMobile ? "1.2rem" : "1.5rem", // Adjust font size for better visibility on mobile
            justifyContent: "center",
            width: isMobile ? "100%" : "auto", // Ensure full width for mobile view
          }}
        >
          <span
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#ff9800",
              marginRight: "8px",
            }}
          ></span>
          <div style={{ width: "150px", textAlign: "justify" }}>
            Step Incomplete
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: isMobile ? "1.2rem" : "1.5rem", // Adjust font size for better visibility on mobile
            justifyContent: "center",
            width: isMobile ? "100%" : "auto", // Ensure full width for mobile view
          }}
        >
          <span
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#4caf50",
              marginRight: "8px",
            }}
          ></span>
          <div style={{ width: "150px", textAlign: "justify" }}>
            Step Complete
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: isMobile ? "1.2rem" : "1.5rem", // Adjust font size for better visibility on mobile
            justifyContent: "center",
            width: isMobile ? "100%" : "auto", // Ensure full width for mobile view
          }}
        >
          <span
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "#f44336",
              marginRight: "8px",
            }}
          ></span>
          <div style={{ width: "150px", textAlign: "justify" }}>Step Error</div>
        </div>
      </div>

      {/* Vertical Line Connector - Hidden on mobile */}
      {!isMobile && (
        <div
          style={{
            position: "absolute",
            left: "36px",
            top: "60px",
            bottom: "20px",
            width: "4px",
            backgroundColor: "#ccc",
            zIndex: 1,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-20px",
              left: "-2px",
              width: "8px",
              height: "20px",
              backgroundColor: "#fff",
              zIndex: 2,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              bottom: "-20px",
              left: "-2px",
              width: "8px",
              height: "20px",
              backgroundColor: "#fff",
              zIndex: 2,
            }}
          ></div>
        </div>
      )}

      {steps.map((step, index) => (
        <div
          key={index}
          onClick={() => handleStepClick(index, step.status)}
          style={{
            display: "flex",
            alignItems: isMobile ? "flex-start" : "center",
            marginBottom: index < steps.length - 1 ? "24px" : "0",
            cursor: "pointer",
            backgroundColor: selectedStep === index ? "#f0f0f5" : "transparent",
            borderColor: selectedStep === index ? "#573a87" : "transparent",
            borderWidth: "2px",
            borderStyle: "solid",
            padding: isMobile ? "8px" : "8px 12px",
            borderRadius: "4px",
            transition: "background-color 0.2s, border-color 0.2s",
            position: "relative",
            width: "100%",
            minWidth: isMobile ? "auto" : "650px",
            zIndex: 2,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "16px",
              position: "relative",
            }}
          >
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor:
                  step.status === "Completed"
                    ? "#4caf50"
                    : step.status === "Incomplete"
                    ? "#ff9800"
                    : "#f44336",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                border: "3px solid #ccc",
              }}
              aria-label={step.status}
            ></span>
          </div>
          <div
            style={{
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: "500",
              color: "#333",
              wordBreak: "break-word",
              flex: 1,
            }}
          >
            <span>{`${index + 1}${getOrdinalSuffix(index + 1)} Step: ${
              step.name
            }`}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

// Helper function to get ordinal suffix
const getOrdinalSuffix = (num) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = num % 100;
  return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
};

export default StepIndicator;
