import React from "react";

function DefaultTailwind() {
  return (
    <div className="bg-black text-center py-10">
      <h1 className="text-3xl font-bold">Hello Tailwind!</h1>
    </div>
  );
}

export default DefaultTailwind;
