import React from "react";
import styled, { css } from "styled-components";
import FrameComponent from "./components/FrameComponent";
import Features from "./components/Features";
import Apply from "./components/Apply";
import ConsumerMerchantItems from "./components/ConsumerMerchantItems";
import FeaturesContainer from "./components/FeaturesContainer";
import FeatureGrid from "./components/FeatureGrid";
import BenefitTitleContainer from "./components/BenefitTitleContainer";
import Footer from "./components/Footer";
import DreamsWithin from "./components/DreamsWithin";
// import Navbar from "./components/Navbar";
import Header from "./components/Header";

const WebsiteContainer = styled.div`
  width: 100%; // Ensure the container covers the full width of the viewport
  min-height: 100vh; // Ensure the container covers the full height of the viewport
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box; // Include padding and border in the element's total width and height
  overflow-x: hidden; // Prevent horizontal scrolling

  @media (max-width: 768px) {
    padding: 0 1rem; // Adding padding for better view on mobile
  }
`;

const HeroSection = styled.section`
  position: relative; // Make it position relative to confine its children
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh; // Ensure minimum height is the full viewport height
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  color: #fff;
  font-family: var(--heading-h6);

  @media (max-width: 768px) {
    padding: 1rem 0;
    font-size: 1.5rem;
  }
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 768px) {
    /* Make the video cover the full height and width of the viewport */
    position: fixed;
    width: 100%;
    height: 100vh;
    /* Maintain aspect ratio */
    object-fit: cover;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const HeroTitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 8rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4.5rem; // Increased font size for mobile
    padding-top: 1rem;
  }
`;

const HeroSubtitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3rem; // Increased font size for mobile
  }
`;

const HeroTitle = styled.div`
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 5.5rem; // Increased font size for mobile
  }
`;

const HeroSubtitle = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3.2rem; // Increased font size for mobile
  }
`;

const BenefitsSection = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 2rem 1rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 2rem; /* Adjusted for mobile */
  color: #fff;
  font-family: var(--heading-h6);
  margin-top: 2rem; /* Adjusted margin for mobile */
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
`;

const BenefitsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const BenefitsHeader = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const BenefitsTitle = styled.div`
  width: 100%; /* Full width on mobile */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const TitleText = styled.div`
  margin-top: 0.5rem;
  text-align: "center";
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 3rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    text-align: "center";
    font-size: 1.6rem;
    font-family: "DM Sans";
    font-weight: 500;
  }
`;

const FullWidthSection = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0; /* Optional: Add padding for better spacing */
  box-sizing: border-box;
`;

const Website = () => {
  return (
    <WebsiteContainer>
      {/* <Navbar /> */}
      <Header />
      <HeroSection>
        <HeroContent>
          <VideoBackground autoPlay loop muted playsInline>
            <source src="../video/Greattr_Video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBackground>
          <HeroTitle>
            <HeroTitleText>Empower Your Future with Greattr</HeroTitleText>
            <HeroSubtitleText>Small Loans, Big Changes</HeroSubtitleText>
          </HeroTitle>
          <HeroSubtitle>
            <b
              style={{
                height: "auto" /* Adjusted for mobile */,
                width: "100%" /* Full width on mobile */,
                position: "relative",
                lineHeight: "1.5",
                display: "inline-block",
                flexShrink: 0,
                textAlign: "center",
              }}
            >
              <TitleText>
                We're Different. Greattr Brings Financial Freedom to Your
                Doorstep.
              </TitleText>
              <TitleText>
                Transforming Lives with Seamless, Accessible Financial Solutions
                for All.
              </TitleText>
            </b>
          </HeroSubtitle>
        </HeroContent>
      </HeroSection>
      <FullWidthSection>
        <FrameComponent />
      </FullWidthSection>
      <FullWidthSection>
        <DreamsWithin />
        <Features />
        <Apply />
        <BenefitsSection>
          <BenefitsContent>
            <BenefitsHeader>
              <BenefitsTitle>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    letterSpacing: "-0.03em",
                    lineHeight: "1.1",
                    fontWeight: 500,
                    fontSize: "7.1rem",
                    color: "black",
                    marginBottom: "10px",
                  }}
                >
                  Discover the Benefits
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontSize: "2.5rem" /* Adjusted for mobile */,
                    lineHeight: "4",
                    fontWeight: 500,
                    color: "black",
                    fontFamily: "var(--text-small)",
                  }}
                >
                  Unlock Your Financial Potential with Greattr
                </div>
              </BenefitsTitle>
            </BenefitsHeader>
            <ConsumerMerchantItems />
            <FeaturesContainer />
          </BenefitsContent>
        </BenefitsSection>
        <FeatureGrid />

        <BenefitTitleContainer />
        <Footer />
      </FullWidthSection>
    </WebsiteContainer>
  );
};

export default Website;
