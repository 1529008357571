import { create } from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set, get) => ({
      user: {
        role: "",
        lastlogin: Date.now(),
        token: "",
        refreshToken: "",
        NavStack: [], // Initialize NavStack as an empty array
      },
      // Update entire user object
      setUser: (userData) => {
        set((state) => ({
          user: {
            ...state.user,
            ...userData,
          },
        }));
      },
      // Retrieve user object
      getUser: () => {
        const state = get();
        return state.user;
      },
      // Set NavStack directly
      setNavStack: (navStack) => {
        set((state) => ({
          user: {
            ...state.user,
            NavStack: navStack,
          },
        }));
      },
      // Push a new route onto NavStack
      pushToNavStack: (route) => {
        set((state) => ({
          user: {
            ...state.user,
            NavStack: [...state.user.NavStack, route],
          },
        }));
      },
      // Pop the top route from NavStack
      popNavStack: () => {
        set((state) => ({
          user: {
            ...state.user,
            NavStack: state.user.NavStack.slice(1),
          },
        }));
      },

      emptyNavStack: () => {
        set((state) => ({
          user: {
            ...state.user,
            NavStack: [],
          },
        }));
      },
    }),
    {
      name: "user-Session", // Name of the item in localStorage
    }
  )
);

export default useStore;
