import React from "react";
import styled from "styled-components";
import Card from "./Card";
import ItemCard from "./ItemCard";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const Container = styled.div`
  align-self: stretch;
  display: flex;
  color: "black";
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.287rem;
  max-width: 100%;
  text-align: center;
  font-size: 2.856rem;
  font-family: var(--heading-h6);
  @media (max-width: 825px) {
    gap: 1.125rem;
  }
  @media (max-width: 1575px) {
    flex-wrap: wrap;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.318rem;
  min-width: 43.938rem;
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: 2.319rem;
  @media (max-width: 825px) {
    gap: 1.188rem;
  }
  @media (max-width: 1250px) {
    min-width: 100%;
  }
`;

const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem;
  box-sizing: border-box;
  gap: 2.4rem;
  max-width: 100%;
  flex-shrink: 0;
  @media (max-width: 825px) {
    gap: 1.188rem;
  }
  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;

const ConsumerMerchantItems = ({ className = "" }) => {
  return (
    <Container className={className}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <Card
          forConsumers="For Consumers"
          enjoyFlexiblePaymentOptio="Enjoy flexible payment options that "
          suitYourBudget="suit your budget."
          app="/app@2x.png"
        />
      </motion.div>
      <RightColumn>
        <Row>
          <ItemCard
            title="Ease of Use"
            description="Apply for loans in minutes with our intuitive app interface."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
          <ItemCard
            title="Financial Flexibility"
            description="Manage your cash flow better with flexible repayment plans."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
        </Row>
        <Row>
          <ItemCard
            title="Instant Transactions"
            description="Access credit to make necessary purchases promptly."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
          <ItemCard
            title="Convenient Access"
            description="Access through a user-friendly app, anytime and anywhere."
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          />
        </Row>
      </RightColumn>
    </Container>
  );
};

ConsumerMerchantItems.propTypes = { className: PropTypes.string };
export default ConsumerMerchantItems;
