import React, { useState, useEffect } from "react";
import "./PersonalDetails.scss";
import greattrlogo from "./assets/logo.png";
import { GESRecords } from "../Utilsx/Encrypt";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { hashAadhaar } from "./utils/encryptionUtils";
import { validateAadhaarNo, validatePanNo } from "./utils/validations";
import { Spinner } from "react-bootstrap";

const PersonalDetails = () => {
  let apiEN = process.env.REACT_APP_API_apiEN;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log("state_Object: ", state);
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [aadhaarNo, setAadhaarNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [mobileNo, setMobileNo] = useState(state?.MobileNumber);
  const [dob, setDob] = useState({ day: "", month: "", year: "" });
  const [address, setAddress] = useState("");
  const [addressState, setAddressState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDob((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let submitData = {
      FirstName: firstName,
      LastName: lastName,
      AadhaarNumber: hashAadhaar(aadhaarNo),
      PAN: panNo,
      MobileNumber: mobileNo,
      DateOfBirth: `${dob.year}-${dob.month}-${dob.day}`,
      Address: `${address}, ${city}, ${addressState}, ${pinCode}`,
    };
    console.log("submitData: ", submitData);
    let EncrytedSubmitData = GESRecords(submitData, apiEN);

    try {
      setisLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/survey/CreateOperatorProfile`,
        EncrytedSubmitData
      );
      console.log("response.data: ", response.data);
      if (response.data.duplicate) {
        alert("Mobile number already exists in the database.");
      } else {
        alert("Operator data inserted successfully.");
        localStorage.setItem(
          "state",
          JSON.stringify({
            MobileNumber: mobileNo,
            AadhaarNumber: aadhaarNo,
            GreattrOperatorId: response.data.GreattrOperatorId,
          })
        );
        navigate("/CompanyInfoOperator", {
          state: {
            MobileNumber: mobileNo,
            AadhaarNumber: aadhaarNo,
            GreattrOperatorId: response.data.GreattrOperatorId,
          },
        });
      }
    } catch (error) {
      console.log(error);
      console.log(
        error.data?.message || "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="personal_company-info">
      <div className="personal_company-info-container">
        <img style={{ width: "20%" }} src={greattrlogo} alt="" />
        <p>1 / 4</p>
        <h3 style={{ fontSize: 20 }}>Basic Information</h3>
        <h5 style={{ fontSize: 16 }}>
          Tell us a bit about yourself. Your personal information is crucial for
          verification purposes and will help us maintain a secure and
          trustworthy platform for all users.
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group1">
            <div className="indi-form-group">
              <label htmlFor="firstName" className="Operator-label-font">
                First Name (as per PAN)*
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="placeholder-font"
                value={firstName}
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="lastName" className="Operator-label-font">
                Last Name (as per PAN)*
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="placeholder-font"
                value={lastName}
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group1">
            <div className="indi-form-group">
              <label htmlFor="aadhaarNo" className="Operator-label-font">
                Aadhaar Number*
              </label>
              <input
                type="text"
                id="aadhaarNo"
                name="aadhaarNo"
                className="placeholder-font"
                value={aadhaarNo}
                placeholder="Enter Aadhaar Number"
                onChange={(e) => {
                  if (!validateAadhaarNo(e.target.value)) {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        aadhaarNO:
                          "Please enter a valid 12 digit Aadhaar number",
                      };
                    });
                    return setAadhaarNo(e.target.value);
                  } else {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        aadhaarNO: null,
                      };
                    });
                    return setAadhaarNo(e.target.value);
                  }
                }}
                required
              />
              {errors.aadhaarNO && (
                <span className="error">{errors.aadhaarNO}</span>
              )}
            </div>
            <div className="indi-form-group">
              <label htmlFor="panNo" className="Operator-label-font">
                PAN
              </label>
              <input
                type="text"
                id="panNo"
                name="panNo"
                className="placeholder-font"
                value={panNo}
                placeholder="Enter PAN"
                onChange={(e) => {
                  if (!validatePanNo(e.target.value.toLocaleUpperCase())) {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        pan: "Please enter a valid PAN number in the format ABCDE1234F",
                      };
                    });
                    return setPanNo(e.target.value.toLocaleUpperCase());
                  } else {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        pan: null,
                      };
                    });
                    return setPanNo(e.target.value.toLocaleUpperCase());
                  }
                }}
                // required
              />
              {errors.pan && <span className="error">{errors.pan}</span>}
            </div>
          </div>
          <div className="address">
            <div className="indi-form-group">
              <label htmlFor="mobileNo" className="Operator-label-font">
                Mobile Number*
              </label>
              <input
                type="text"
                id="mobileNo"
                name="mobileNo"
                className="placeholder-font"
                value={mobileNo}
                placeholder="Enter Mobile Number"
                disabled={true}
                // required
              />
            </div>
          </div>
          <div
            className="indi-form-group"
            style={{
              width: "100%",
              // backgroundColor: "red",
              display: "block",
            }}
          >
            <label htmlFor="dob" className="Operator-label-font">
              Date of Birth*
            </label>
            <br />
            <div className="date-dropdowns">
              <select
                name="day"
                value={dob.day}
                className="placeholder-font"
                onChange={handleDateChange}
                style={{ marginLeft: 5, marginRight: 5 }}
                required
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                className="placeholder-font"
                name="month"
                value={dob.month}
                style={{ marginLeft: 5, marginRight: 5 }}
                onChange={handleDateChange}
                required
              >
                <option value="">Month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                className="placeholder-font"
                name="year"
                value={dob.year}
                onChange={handleDateChange}
                required
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="address">
            <label htmlFor="address" className="Operator-label-font">
              Address*
            </label>
            <input
              type="text"
              id="address"
              name="address"
              className="placeholder-font"
              value={address}
              placeholder="Enter Address"
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="last-row">
            <div className="indi-form-group">
              <label htmlFor="addressState" className="Operator-label-font">
                State*
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="placeholder-font"
                value={addressState}
                placeholder="Select State"
                onChange={(e) => setAddressState(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="city" className="Operator-label-font">
                City*
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="placeholder-font"
                value={city}
                placeholder="Select City"
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="pinCode" className="Operator-label-font">
                Pincode*
              </label>
              <input
                type="text"
                id="pinCode"
                name="pinCode"
                className="placeholder-font"
                value={pinCode}
                placeholder="Enter Pincode"
                onChange={(e) => setPinCode(e.target.value)}
                required
              />
            </div>
          </div>
          {/* <button onClick={() => alert(`${dob.year}-${dob.month}-${dob.day}`)}>dkasnkadsn don</button>
                    <br /><br /> */}
          <button type="submit" disabled={isLoading} style={{ padding: 15 }}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
