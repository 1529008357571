import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const GridContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.143rem;
  text-align: left;
  font-size: 1.288rem;
  color: var(--text-default);
  font-family: var(--font-text-small);
  ${(props) => props.className}
`;

const GridItem = styled(motion.div)`
  display: flex;
  flex-row: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.143rem;
`;

const Badge = styled(motion.div)`
  height: 1.856rem;
  width: 1.856rem;
  border-radius: 114.29px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.281rem 0.25rem 0.287rem;
  box-sizing: border-box;
`;

const BadgeImage = styled(motion.img)`
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;

const Text = styled(motion.div)`
  position: relative;
  leading-trim: both;
  text-edge: cap;
  line-height: 180%;
  font-weight: 500;
  font-family: "Inter";
  font-size: 1.5rem;
  animation: ${pulse} 2s infinite;

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 1.875rem;
  }
`;

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const Grid = ({
  className = "",
  badgeName,
  paperless,
  badgeName1,
  noCollateralFree,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <GridContainer
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={itemVariants}
    >
      <GridItem variants={itemVariants}>
        <Badge name={badgeName} variants={itemVariants}>
          <BadgeImage
            loading="lazy"
            alt=""
            src="/richeckfill.svg"
            variants={itemVariants}
          />
        </Badge>
        <Text variants={itemVariants}>{paperless}</Text>
      </GridItem>
      <GridItem variants={itemVariants}>
        <Badge name={badgeName1} variants={itemVariants}>
          <BadgeImage
            loading="lazy"
            alt=""
            src="/richeckfill.svg"
            variants={itemVariants}
          />
        </Badge>
        <Text variants={itemVariants}>{noCollateralFree}</Text>
      </GridItem>
    </GridContainer>
  );
};

Grid.propTypes = {
  className: PropTypes.string,
  badgeName: PropTypes.string,
  paperless: PropTypes.string,
  badgeName1: PropTypes.string,
  noCollateralFree: PropTypes.string,
};

export default Grid;
