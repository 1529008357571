import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const StyledColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding-top: 0rem;
  padding-bottom: 0.006rem;
  box-sizing: border-box;
  gap: 3.431rem;
  min-width: 27.875rem;
  max-width: 100%;
  font-size: 1.588rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
    gap: 1.688rem;
    min-width: 100%;
  }
`;

const Heading = styled.div`
  align-self: stretch;
  font-size: 5.569rem;
  letter-spacing: -0.03em;
  line-height: 110%;
  font-weight: 500;
  flex-shrink: 0;

  @media (max-width: 825px) {
    font-size: 3.625rem;
    line-height: 4rem;
  }

  @media (max-width: 450px) {
    font-size: 3.75rem;
    line-height: 5rem;
  }
`;

const ContactContainer = styled.div`
  width: 27.763rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 0;
  box-sizing: border-box;
  gap: 2.287rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 450px) {
    gap: 1.125rem;
  }
`;

const ContactRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.712rem;
  max-width: 100%;
  flex-shrink: 0;

  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`;

const ContactIcon = styled.div`
  height: 3.569rem;
  width: 3.569rem;
  border-radius: 114.29px;
  background-color: var(--whitesmoke-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.781rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.787rem;
  box-sizing: border-box;
`;

const ContactInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 0.287rem;
  min-width: 14.625rem;
  max-width: 100%;
`;

const ContactName = styled.div`
  align-self: stretch;
  letter-spacing: -0.03em;
  line-height: 1.813rem;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 450px) {
    font-size: 1.4rem;
    line-height: 2.438rem;
  }
`;

const ContactDetail = styled.div`
  align-self: stretch;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 500;
  font-family: var(--text-small);
  color: var(--text-muted);
`;

const SupportRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.568rem;
  color: var(--background-primary);
`;

const SupportText = styled.div`
  letter-spacing: -0.03em;
  line-height: 1.313rem;
  font-weight: 500;
  display: inline-block;
  min-width: 4.75rem;

  @media (max-width: 450px) {
    font-size: 1.4rem;
    line-height: 1rem;
    min-width: 2.75rem;
  }
`;

const Image = styled.img`
  width: 4rem;

  @media (max-width: 450px) {
    width: 2.5rem;
  }
`;

const Column = ({
  className = "",
  riarrowDownLine,
  columnFlex,
  columnMinWidth,
  columnWidth,
}) => {
  const columnStyle = useMemo(() => {
    return {
      flex: columnFlex,
      minWidth: columnMinWidth,
      width: columnWidth,
    };
  }, [columnFlex, columnMinWidth, columnWidth]);

  return (
    <StyledColumn className={className} style={columnStyle}>
      <Heading>Need help?</Heading>
      <ContactContainer>
        <ContactRow>
          <ContactIcon>
            <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img
                className="h-8 w-8 relative"
                loading="lazy"
                alt="Phone icon"
                src="/riphonefill.svg"
              />
            </motion.div>
          </ContactIcon>
          <ContactInfo>
            <ContactName>+91 79775 70052</ContactName>
            <ContactDetail>Support Hotline</ContactDetail>
          </ContactInfo>
        </ContactRow>
        <ContactRow>
          <ContactIcon>
            <motion.div
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img
                className="h-[2rem] w-[2rem] relative"
                loading="lazy"
                alt=""
                src="/rimailunreadline.svg"
              />
            </motion.div>
          </ContactIcon>
          <ContactInfo>
            <ContactName>office@greattr.com</ContactName>
            <ContactDetail>Support Email</ContactDetail>
          </ContactInfo>
        </ContactRow>
      </ContactContainer>
      {/* <SupportRow>
        <SupportText>Support</SupportText>
        <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <Image
            className="h-[1.713rem] w-[1.713rem] relative object-contain"
            loading="lazy"
            alt=""
            src={riarrowDownLine}
          />
        </motion.div>
      </SupportRow> */}
    </StyledColumn>
  );
};

Column.propTypes = {
  className: PropTypes.string,
  riarrowDownLine: PropTypes.string,

  /** Style props */
  columnFlex: PropTypes.any,
  columnMinWidth: PropTypes.any,
  columnWidth: PropTypes.any,
};

export default Column;
