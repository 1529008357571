import React, { useState } from "react";

// Sanitize the input by removing potentially dangerous characters
const sanitizeInput = (input) => {
  // Basic sanitation to remove dangerous characters like SQL keywords
  return input.replace(/['";\-\-]/g, ""); // This is a basic sanitizer, you may want to extend it
};

const TextInputWrapper = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value || "");

  const handleInputChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    setInputValue(sanitizedValue);
    if (onChange) {
      onChange(sanitizedValue);
    }
  };
  //
  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      {...props}
    />
  );
};

export default TextInputWrapper;
